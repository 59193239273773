<form novalidate [formGroup]="taskForm">
  <div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="accent m-0" fxFlex="1 0 auto" fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Group Status -->
        <div class="members" *ngIf="module !== 'time_tracker' || isAdmin">
          <button [matMenuTriggerFor]="allMembersMenu" mat-icon-button class="section-header-point-cursor"
            [disabled]="readonly">
            <mat-icon matTooltip="Reporter">person</mat-icon>
          </button>

          <button [matMenuTriggerFor]="subMembersMenu" mat-icon-button class="section-header-point-cursor"
            [disabled]="readonly">
            <mat-icon matTooltip="{{ 'TASK_MANAGEMENT.co_reporter' | translate }}">group
            </mat-icon>
          </button>
        </div>

        <div>
          {{ taskData?.clinicName || currentClinic?.name }}
        </div>

        <div>
          <ng-container *ngIf="module !== 'time_tracker' || isAdmin">
            <button *ngIf="!newTask" mat-icon-button type="button" aria-label="Save Group" (click)="deleteTask()"
              [disabled]="readonly">
              <mat-icon matTooltip="{{ 'Delete' | translate }}">delete</mat-icon>
            </button>
            <!-- Save Button -->
            <button *ngIf="type === 'task'" mat-icon-button type="button" aria-label="Save Group" (click)="saveGroup()"
              [disabled]="readonly">
              <mat-icon *ngIf="newTask" matTooltip="{{ 'THREAD.save' | translate }}">save
              </mat-icon>
              <mat-icon *ngIf="!newTask" matTooltip="{{ 'THREAD.update' | translate }}" [disabled]="readonly">save
              </mat-icon>
            </button>

            <!-- Save Button -->

            <!-- Save button for Plan -->
            <button *ngIf="type === 'plan'" mat-icon-button type="button" aria-label="Save Group" (click)="savePlan()"
              [disabled]="readonly">
              <mat-icon *ngIf="newTask" matTooltip="{{ 'THREAD.save' | translate }}">save
              </mat-icon>
              <mat-icon *ngIf="!newTask" matTooltip="{{ 'THREAD.update' | translate }}" [disabled]="readonly">save
              </mat-icon>
            </button>
            <!-- Save button for Plan -->
          </ng-container>

          <!-- CLOSE DIALOG BUTTON -->
          <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close Dialog">
            <mat-icon matTooltip="{{ 'THREAD.close' | translate }}">close
            </mat-icon>
          </button>
          <!-- / CLOSE DIALOG BUTTON -->
        </div>
      </div>
    </mat-toolbar>
    <div class="loading-div" *ngIf="loadingProgress">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    <div mat-dialog-content class="py-24 px-0 m-0">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="80" class="border-right">
          <div>
          </div>

          <div class="sections">
            <div class="section no-border" fxLayout="column">
              <div fxFlex class="labels">
                <div class="section-content">
                  <mat-chip-list class="label-chips">
                    <ng-container *ngFor="
                        let task of taskList;
                        let i = index;
                        let lastItem = last
                      ">
                      <mat-chip class="label-chip mb-4" [ngStyle]="{
                          'pointer-events':
                            task.disabled
                              ? 'none'
                              : 'inherit'
                        }" [ngClass]="
                          taskForm.get('task').value === task.name
                            ? task.color
                            : ''
                        " [disabled]="task.disabled || readonly" [selectable]="task.disabled"
                        fxLayoutAlign="start center" matTooltip="{{ task.tooltip | translate }}">
                        <span class="section-header-point-cursor" (click)="taskStatus(task.name)">{{ task.name |
                          uppercase }}</span>
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </div>
              </div>
            </div>
            <div class="section">
              <!-- NAME -->
              <div class="card-name px-24 mb-8">
                <rich-text-field formControlName="title" [data]="'<></>'" [limit]="30"
                  [placeholder]="'THREAD.title' | translate"
                  [disabled]="module === 'time_tracker' && !isAdmin || readonly"></rich-text-field>
              </div>
              <!-- / NAME -->
              <!-- DESCRIPTION -->
              <div fxLayout="row" fxLayoutAlign="start center">
                <div class="full-width desc-field mb-16">
                  <rich-text-field formControlName="description" [data]="'<></>'"
                    [placeholder]="'THREAD.description' | translate"
                    [disabled]="module === 'time_tracker' && !isAdmin || readonly"></rich-text-field>
                </div>
                <mat-icon (click)="attachmentsC.pickFile()" class="attachement-icon mb-10"
                  [style.visibility]="module === 'time_tracker' && !isAdmin || readonly ? 'hidden': 'visible'">attachment
                </mat-icon>
              </div>
              <!-- / DESCRIPTION -->
            </div>
            <!-- ATTACHMENTS SECTION -->
            <div class="section">
              <div class="attachments">
                <div class="section-header section-header-point-cursor" fxLayout="row"
                  fxLayoutAlign="space-between start">
                  <button mat-icon-button (click)="editAttachment()" *ngIf="taskData?.files?.length > 0 && !readonly">
                    <mat-icon class="mr-8">edit</mat-icon>
                  </button>
                </div>
                <div class="section-content" *ngIf="showAttachment">
                  <app-gallery [items]="taskData?.files" size="small"></app-gallery>
                </div>
                <div class="section-content" *ngIf="!showAttachment && groupSelected">
                  <app-attachments #attachmentsC (filesRemoved)="removeAttachment($event)"
                    [allowLargeFiles]="allowLargeFiles" [files]="taskData?.files"
                    [readonly]="readonly"></app-attachments>
                </div>
                <div class="section-content" *ngIf="!showAttachment && !groupSelected">
                  <app-attachments #attachmentsC (filesRemoved)="removeAttachment($event)"
                    [allowLargeFiles]="allowLargeFiles" [files]="taskData?.files"
                    [readonly]="readonly"></app-attachments>
                </div>
              </div>
            </div>
            <!-- / ATTACHMENTS SECTION -->
            <!-- COMMENTS SECTION -->
            <div class="section no-border p-0" *ngIf="!newTask">
              <app-task-comment-files [taskData]="taskData"></app-task-comment-files>
            </div>
            <!-- / COMMENTS SECTION -->
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="sections">
            <div class="section" fxLayout="column">
              <div fxFlex class="labels">
                <div fxLayout="column">
                  <div class="due-date" fxFlex="0 1 auto">
                    <mat-form-field appearance="outline" class="w-100-p">
                      <mat-label>{{ "SLIDE.date" | translate }}</mat-label>
                      <input formControlName="date" matInput [matDatepicker]="picker" [disabled]="readonly"
                        [readonly]="readonly" />
                      <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="readonly"></mat-datepicker-toggle>
                      <mat-datepicker #picker [disabled]="readonly"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="due-date" fxFlex="0 1 auto">
                    <mat-form-field appearance="outline" class="w-100-p">
                      <mat-label>{{ "GROUPS.time" | translate }}</mat-label>
                      <input formControlName="startTime" matInput [readonly]="allDaySelected || readonly"
                        [disabled]="allDaySelected || readonly" [format]="24"
                        [ngxTimepicker]="startTimeToggleTimepicker" />
                      <ngx-material-timepicker-toggle matSuffix [for]="startTimeToggleTimepicker"
                        [disabled]="readonly"></ngx-material-timepicker-toggle>
                      <ngx-material-timepicker #startTimeToggleTimepicker></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                  <div class="due-date" fxFlex="0 1 auto" *ngIf="showEndTime">
                    <mat-form-field appearance="outline" class="w-100-p end-time">
                      <mat-label>{{ "SLIDE.end_time" | translate }}</mat-label>
                      <input formControlName="taskEndDate" matInput [readonly]="allDaySelected || readonly"
                        [disabled]="allDaySelected || readonly" [format]="24"
                        [ngxTimepicker]="endTimeToggleTimepicker" />

                      <ngx-material-timepicker-toggle matSuffix [for]="endTimeToggleTimepicker"
                        [disabled]="readonly"></ngx-material-timepicker-toggle>
                      <ngx-material-timepicker #endTimeToggleTimepicker [disabled]="readonly"></ngx-material-timepicker>
                    </mat-form-field>

                    <small class="error-color" *ngIf="taskForm?.errors?.timeRangeError && !readonly">
                      {{ "TASKS.errors.END_TIME_MUST_BE_GREATER_THAN_START_TIME" | translate }}
                    </small>

                    <small class="error-color" *ngIf="taskForm?.errors?.futureTimeError && !readonly">
                      {{ "TASKS.errors.FUTURE_TIME_CANNOT_BE_ADDED" | translate }}
                    </small>

                    <small class="error-color" *ngIf="taskForm?.errors?.timeError && !readonly">
                      {{ "TASKS.errors.CURRENT_TIME_CANNOT_BE_ADDED" | translate }}
                    </small>
                  </div>

                  <div class="due-date" fxFlex="0 1 auto" *ngIf="module !== 'time_tracker'">
                    <mat-form-field appearance="outline" class="w-100-p" [disabled]="readonly">
                      <mat-label>Interval</mat-label>
                      <mat-select formControlName="timeInterval" [disabled]="readonly">
                        <mat-option (onSelectionChange)="intervalChange($event, index)"
                          *ngFor="let time of timeInterval; let index = index" [value]="time">
                          {{ time }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="section" fxLayout="column">
              <div fxFlex class="members">
                <div class="section-header section-header-point-cursor" fxLayout="row" fxLayoutAlign="start center"
                  [matMenuTriggerFor]="allMembersMenu" (click)="subMenuListener()"
                  *ngIf="(module !== 'time_tracker' && disabledNormalUser()) || (this.module === 'time_tracker' && isAdmin)">
                  <mat-icon class="mr-8">person</mat-icon>
                  <span class="section-title">Reporter</span>
                </div>

                <div class="section-header section-header-point-cursor" fxLayout="row" fxLayoutAlign="start center"
                  *ngIf="module === 'time_tracker' && !isAdmin">
                  <mat-icon class="mr-8">person</mat-icon>
                  <span class="section-title">Reporter</span>
                </div>

                <div class="section-content">
                  <div class="member-chips" fxLayout="row">
                    <div class="member-chip mb-8" fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="reporterObj">
                      <profile-image [src]="reporterObj.file?.thumb_url" matRipple [userProfile]="reporterObj.id"
                        [matTooltip]="reporterObj.fullName" class="avatar cursor-pointer"></profile-image>
                    </div>
                  </div>
                </div>
              </div>

              <div fxFlex class="members">
                <div class="section-header section-header-point-cursor" fxLayout="row" fxLayoutAlign="start center"
                  (click)="subMenuListener()" [matMenuTriggerFor]="subMembersMenu"
                  *ngIf="!isMyDoc && (module !== 'time_tracker' || isAdmin)">
                  <mat-icon class="mr-8">group</mat-icon>
                  <span class="section-title">{{
                    "TASK_MANAGEMENT.co_reporter" | translate
                    }}</span>
                </div>

                <div class="section-header section-header-point-cursor" fxLayout="row" fxLayoutAlign="start center"
                  *ngIf="!isMyDoc && module === 'time_tracker' && !isAdmin">
                  <mat-icon class="mr-8">group</mat-icon>
                  <span class="section-title">{{
                    "TASK_MANAGEMENT.co_reporter" | translate
                    }}</span>
                </div>

                <div class="section-content">
                  <div class="member-chips" fxLayout="row wrap">
                    <div class="member-chip mb-4" *ngFor="let selectedUser of selectedUsers">
                      <profile-image [src]="selectedUser.file?.thumb_url" matRipple [userProfile]="selectedUser.id"
                        [matTooltip]="selectedUser.fullName" class="avatar mb-8"></profile-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center"
      *ngIf="module !== 'time_tracker' || isAdmin">

      <button *ngIf="newTask && type === 'task'" mat-raised-button color="accent" class="post-button"
        (click)="saveGroup()" aria-label="save">
        {{ "THREAD.save" | translate }}
      </button>

      <button *ngIf="!newTask && type === 'task'" mat-raised-button color="accent" class="post-button"
        (click)="saveGroup()" aria-label="update" [disabled]="readonly">
        {{ "THREAD.update" | translate }}
      </button>

      <button *ngIf="newTask && type === 'plan'" mat-raised-button color="accent" class="post-button"
        (click)="savePlan()" aria-label="save">
        {{ "THREAD.save" | translate }}
      </button>

      <button *ngIf="!newTask && type === 'plan'" mat-raised-button color="accent" class="post-button"
        (click)="savePlan()" aria-label="update" [disabled]="readonly">
        {{ "THREAD.update" | translate }}
      </button>

      <button *ngIf="!newTask" mat-raised-button color="warn" class="post-button" (click)="deleteTask()"
        aria-label="delete" [disabled]="readonly">
        {{ "DASHBOARD_TASK.delete" | translate }}
      </button>
    </div>
  </div>
</form>


<mat-menu #allMembersMenu="matMenu" [overlapTrigger]="false" class="mat-menu-height">
  <div fxFlex fxLayout="column" class="scrumboard-members-menu" *ngFor="let member of groupUserList; let i = index">
    <div fxLayout="row" fxLayoutAlign="start center" class="ml-8 section-header-point-cursor"
      (click)="userSelectionReporter($event, i)">
      <profile-image [src]="member.file?.thumb_url" class="avatar"></profile-image>
      <p class="member-name">{{ member.fullName }}</p>
    </div>
  </div>
</mat-menu>

<mat-menu #subMembersMenu="matMenu" [overlapTrigger]="false" class="specific-height">
  <div fxFlex fxLayout="column" class="scrumboard-members-menu" *ngFor="let member of subUserList; let i = index"
    (click)="$event.stopPropagation()">
    <mat-checkbox class="member px-16" (change)="subUserSelection($event, i)" [checked]="member.selectedUser"
      [disabled]="readonly">
      <div fxLayout="row" fxLayoutAlign="start center">
        <profile-image [src]="member.file?.thumb_url" class="avatar"></profile-image>
        <p class="member-name">{{ member.fullName }}</p>
      </div>
    </mat-checkbox>
  </div>

  <div *ngIf="subUserList.length === 0" class="no-sub-users">
    No Sub Users
  </div>
</mat-menu>