import { over, overEvery } from "lodash";

export const locale = {
  lang: "en",
  data: {
    NAV: {
      APPLICATIONS: "Application",
      DASHBOARD: "Dashboard",
      NOTIFICATION: {
        TITLE: "Notifications",
        BADGE: "",
      },
      NEWGROUP: {
        FINDGROUP: "Find group",
        ADDGROUP: "Create new group",
      },
      MESSAGES: {
        TITLE: "Chat",
        BADGE: "",
      },
      CONTACTS: {
        TITLE: "Contacts",
        BADGE: "",
      },
      OTHER: "Other",
      "Quick-Access": "Quick Access",
      "Back-Office": "Back Office",
      "User-Management": " User Management",
      "Group-Management": "Group Management",
      "Coupon-Management": "Coupon Management",
      COMMUNITY: "Community",
      GROUP: "Groups",
    },
    DASHBOARD: {
      WELCOME_BACK: "Welcome Back",
      budget_summary: "Budget Summary",
      HOME: "Home",
    },
    TIMELINE: {
      write_something_here: "Write Something Here...",
      mention_somebody: "Mention Somebody",
      add_photo: "Add Photo",
      add_location: "Add Location",
      post: "Post",
    },
    DASHBOARD_TASK: {
      filter_tasks: "Filter Tasks",
      edit: "Edit",
      delete: "Delete",
    },
    post: {
      thread_notification_on: "Thread Notification On",
      thread_notification_off: "Thread Notification Off",
      post_comment: "Send",
      add_comment: "Add a Comment",
      update_comment: "Update Comment",
      notice: "Notice",
      important: "Important",
      critical: "Critical",
      show: "Show",
      more_comments: "More Comments",
      show_more: "... Show More",
      show_less: " Show less",
      pin_post: "Post Pinned",
      unpin_post: "Post Unpinned",
      location: "Location",
      check_time_status: "edited",
    },
    TASKS: {
      file: "Files",
      comment: "Comments",

      errors: {
        END_TIME_MUST_BE_GREATER_THAN_START_TIME:
          "End Time must be greater than Start Time",
        FUTURE_TIME_CANNOT_BE_ADDED: "Tasks cannot be planned ahead of time.",
        CURRENT_TIME_CANNOT_BE_ADDED:
          "you cannot select current time for check in/out update",
      },
    },
    NOTIFICATIONS: {
      notification: "Notifications",
      select_notification_to_view: "Select a notification to read the content!",
    },
    TOOLBAR: {
      new_version:
        "A new update is available, please<a> click here </a>to view...",
      invite_to_group: "Invite To Group",
      create_group: "Create Group",
      create_task: "Create Task",
      create_contact: "Create Contact",
      create_coupon: "Create Coupon",
      my_profile: "My Profile",
      inbox: "Inbox",
      logout: "Logout",
      no_shortcuts_yet: "No Shortcuts yet!",
      click_to_add_remove_shortcut: "Click to Add/Remove Shortcuts.",
      remove: "Remove",
      cancel: "Cancel",
      confirm: "Confirm",
      update: "Update",
    },
    CHAT: {
      select_contact_to_chat: "Select a contact to start a chat!",
      search_or_start_chat: "Search or Start new chat",
      start_conversation_by_typing:
        "Start a conversation by typing your message below.",
      select_contact_to_conversation:
        "Select a contact to start a conversation!",
      type_your_message: "Type your Message",
      accept_connection_request: "Accept Connection Request",
      accept_connection: "Accept Connection",
      reject_connection: "Reject Connection",
      send_connection: "Send Connection",
      remove_connection: "Remove Connection",
      delete_chat: "Delete Chat",
      send_message: "Send Message",
      away: "away",
      do_not_disturb: "Do Not Disturb",
      mood: "Mood",
      other_users: "Other Users",
      contact_info: "Contact Information",
      date_of_birth: "Date Of Birth",
      gender: "Gender",
      websites_links: "Websites & Links",
      mobile_phone: "Mobile Phone",
      mobile_no: "Mobile Number",
    },
    GROUPS: {
      welcome: "WELCOME TO GROUPS",
      sub_welcome:
        "Groups will help you through the process of building a community or a team, " +
        " or you can use it as your personal space to collect, order and plan you digital stuff..",
      filter_group_by: "Filter groups by name...",
      all: "All",
      valid_till:
        "You are currently using a free trial period for pro groups, valid till",
      your_groups: "Your Groups",
      other_groups: "Other groups",
      go_to_group: "Go to Group",
      no_group_found: "No Group Found",
      no_group_found_text:
        "If the group you are looking for is not displayed, it may be because the group's administrator has set the group's settings to Hidden. You can contact the administrator and ask them to invite you, or to change the group's settings to Visible.",
      search_for_group: "Search for a group",
      patient_caller_slide: "Caller Slide",
      slide_show: "Slides",
      day: "Day",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
      week: "Week",
      month: "Month",
      become_admin: "Become An Admin",
      group_request: "Group Request",
      group_member: "Group Members",
      members: "Members",
      leave_group: "Leave Group",
      send_group_email: "Send Group Email",
      setting: "Settings",
      select_room: "Select Room",
      enter_name: "Enter Name",
      reset_form: "Reset Form",
      patient_name: "Patient Name",
      delete_patient_caller: "Delete Patient Caller",
      edit_patient_caller: "Edit Patient Caller",
      time: "Start Time",
      caller: "Caller",
      room: "Room",
      create_post: "Create Post",
      timeline: "Timeline",
      task_management: "Status",
      planning: "Calendar",
      contact: "Contacts",
      files: "Files",
      membership: "Membership",
      only_administrator_can_approve: "Admins",
      all_members_can_approve: "All Members",
      visible_to_everyone: "Visible",
      visible_to_everyone_tooltip: "Shows in lists but only members can access",
      not_visible_to_everyone: "Not Visible",
      not_visible_to_everyone_tooltip: "Only invited users can access",
      group_visibility: "Group Visibility",
      visibility: "Visibility",
      hidden: "Hidden",
      slider: "Slider",
      task_control_ring: "Task Control Ring",
      all_mem_can_approve: "All members can approve.",
      profile_form: "Profile Form",
      group_profile: "Group profile",
      group_security: "Security",
      group_plan_billing: "Plan & Billing",
      group_Invoices: "Invoices",
      group_spendings: "Usage",
      group_settings: "Settings",
      group_allow_sharing: "Allow sharing",
      group_profile_desc: "Manage the groups expression and identity",
      group_security_desc: "Manage rights and visibility",
      group_plan_billing_desc:
        "Manage group and inventory plan, as well as payment and invoice information",
      group_invoices_desc: "Manage and view all group invoices",
      group_spendings_desc: "Get an overview of the group's data consumption",
      group_setting_desc: "Manage opportunities and limitations",
      allow_large_file_desc:
        "Allow users to upload large files. If allowed there will be no size limitation. Be aware that this can increase the group's storage consumption.",
      allow_sharing_desc:
        "Allow users to share thread content and contacts from the group. Userspecific info such as user info and comments are not shared.",
      group_privacy: "Privacy",
      group_backup: "Group Backup",
      group_visible_desc:
        " The group will be sight in search and anyone can apply for membership",
      group_hidden_desc:
        "The group will be hidden. Only invited users will have access",
      group_membership_desc: "Only group administrators can approve members",
      group_members_desc:
        "Everyone in the group can approve new ones\n" + "members",
      group_profile_warning:
        "The following information is displayed publicly, be carefull",

      economy: "Economy",
      save: "Save",
      invoice_overview: "Invoice overview",
      zip_code: "Zip code",
      city: "City",
      graphic: "Graphic\n",
      module_settings: "MODULE SETTINGS",
      slide_settings: "Slide Settings",
      caller_settings: "Caller Settings",
      planning_settings: "Planning",
      background_color: "Background Color",
      add_more: "Add More",
      cannot_be_empty: "cannot be empty.",
      group_approved_by: "Membership approval by",
      show_on_dashboard: "Show On Dashboard",
      create_new_group: "Create New Group",
      go_today: "Today",
      header: "Ouups!",
      not_accessible: "Link is not accessable.",
      content:
        "The page you are trying to reach does not exist or the page can not be accessed. This might be due to the security settings of the page or group.",
      my_docs: "MY DOCS",
      my_timeline: "My Timeline",
      my_files: "My Files",
      my_calendar: "My Calendar",
      my_task: "My Tasks",
      my_contact: "My Contacts",
      my_planning: "Planning",
      my_time_tracker: "Time Tracker",
      click_to_download_qr_code: "Klik for at downloade QR-kode",
      qr_code: "QR Code",
      toggle_buttons: {
        overview: "Overview",
        working_hours: "Working Hours",
      },
      EXPORT_CSV: {
        from_date: "From date",
        to_date: "To date",
        export_as_csv: "Export as CSV",
      },
      CHECKIN_CHECKOUT: {
        in: "IN",
        out: "OUT",
        edited: "Edited",
        FORM: {
          date: "Date",
          check_in: "Check in",
          check_out: "Check out",
          date_error: "Invalid date entered.",
          check_in_future_error: "Check-in cannot be in the future.",
          check_out_future_error: "Check-out cannot be in the future.",
          check_in_over_error: "Check-in time must be before Check-out time.",
          check_in_same_now_error:
            "Check-in cannot be updated to the current time.",
          check_out_same_now_error:
            "Check-out cannot be updated to the current time.",
        },
      },
      SETTINGS: {
        LEFT_SIDEBAR: {
          MODULES: {
            title: "Modules",
            description: "Settings for the individual modules",

            calling: "Calling",
            dias: "Dias",
            planning: "Planning",
            time_tracker: "Time Tracker",

            TIME_TRACKER: {
              title: "Time Tracker",
              control_options: "Control Options",
              see_registrations:
                "Group members can see each other's registrations.",
              same_day_checkout:
                "Check out same day (Automatic Check out 23:59)",
              description:
                "Activate this module to allow your members to check in/out.",

              Options: "Other Options",
              check_in: "Check-in - Requires QR",
              check_out: "Check-Out - Requires QR",
              show_checkin_check_out_as_post:
                "Show check in/out as a post on the timeline",
              timestamp_slides: "Show QR code on Slides.",
              timestamp_group: "Show QR code on the Group front page.",
              timestamp_group_slide: "Show QR on groupMainPage + on Slideshow.",
              timestamp_qr_rotate:
                "Use varying QR (the code will change several times a day at intervals of a few minutes",
              GPS_location: "Use GPS position",
              working_hours: "Working hours (first Check in, last Check out)",
              working_hours_description:
                "Activate working hours by when you work, etc.",

              monday: "Monday",
              tuesday: "Tuesday",
              wednesday: "Wednesday",
              thursday: "Thursday",
              friday: "Friday",
              saturday: "Saturday",
              sunday: "Sunday",

              mon: "M",
              tue: "T",
              wed: "W",
              thu: "T",
              fri: "F",
              sat: "S",
              sun: "S",

              from: "From",
              to: "To",

              ERRORS: {
                overlap: "This time slot overlaps with another.",
                invalid:
                  "End time (to) must be greater than start time (from).",
                invalid_from:
                  "Please enter a valid start time (from) from the drop down.",
                invalid_to:
                  "Please enter a valid end time (to) from the drop down.",
                select_a_day:
                  "Please select at-least 1 day, otherwise you may disable Working Hours.",
              },
            },
          },
        },
      },

      GROUP: {
        TIME_TRACKER: {
          DATE: {
            DATE_INTERVAL: "Date Interval",

            THIS_YEAR: "This Year",
            THIS_MONTH: "This Month",
            THIS_WEEK: "This Week",

            LAST_YEAR: "Last Year",
            LAST_MONTH: "Last Month",
            LAST_WEEK: "Last Week",

            CUSTOM: "Custom",

            SICK: "Sick",
            ON_LEAVE: "Holiday",
            OTHER: "Other",
            CHECK_IN: "Check IN",
          },

          STAT_OVERVIEW: {
            no_data: "No data to show yet!",
          },
        },
      },
    },
    SEARCH: {
      search: "Search",
      go_to_group: "Go To Group",
      cancel_membership: "Cancel Membership",
      request_membership: "Request Membership",
      search_folders: "Search Folders",
      search_in_folders: "Search Folder",
      search_files: "Search Files",
      no_search_folders: "No Folders Found",
      show_more: "Show More",
      no_results: "No Results Found",
      groups: "Groups",
      search_for_app_page: "Search for an app or a page",
      no_group_folder_selected: "No Group or Folder selected.",
      threads: "Threads",
    },
    THREAD: {
      priority: "Priorities",
      show_destination: "Show destination confirmation pop up",
      save: "Create",
      update: "Update",
      close: "Close",
      description: "Description",
      description_req: "Description is Required",
      attachments: "Attachments",
      add_attachments: "Add an Attachment",
      where_to_create_thread: "Where to create the thread",
      where_to_update_thread: "Where to update the thread",
      your_thread_will_be_created: "Your Thread will be created in here..",
      your_thread_will_be_updated: "Your Thread will be updated in here..",
      location_group: "Location (Group)",
      change_group: "Change Group",
      create_thread: "Create Thread",
      update_thread: "Update Thread",
      dont_show_this_confirmation_page: "Don't show this confirmation page",
      title: "Title",
      share: "Share",
      choose_group: "Choose group",
      fixed: "Fixed",
      all_group: "All Groups",
      search_for_group: "Search for group",
      check_in: "Check in",
      check_out: "Check Out",
      checkInOut_timeError:
        "you cannot select current time for check in/out update",
      checkout_TimeRangeError: "Check-in time must be less than Check-out time",
    },
    SLIDE: {
      one: "2 x small content",
      sec_one: "2 x small icons",
      two: "2 x large content",
      sec_two: "1 x small icons",
      three: "1 x small content",
      sec_three: "1 x small icons",
      four: "1 x small content",
      sec_four: "",
      five: "Only Image",
      sec_five: "Background only",
      six: "Only Video",
      sec_six: "Background only",
      above: "Above",
      below: "Below",
      image: "Billede",
      colors: "Colors",
      schedule: "Schedule",
      end: "End",
      slide_message: "Slide Message is required",
      start_time: "Start Time",
      slide_start_message: "Slide Message Start Time is required!",
      end_time: "End Time",
      slide_end_message: "Slide Message End Time is required!",
      select_background: " Select Background",
      select_template_type: "Please Select a Template Type.",
      select_template: "Select Template",
      next: "Next",
      fill_out_slide: "Fill out Slide Detail",
      background: "Background",
      text_background: "Text Background",
      text_background_color: "Text Background Color is required!",
      text_color: "Text Color",
      text_color_req: "Text Color is required!",
      text_size: "Text Size",
      text_size_req: "Text Size is required!",
      choose_file: "Choose File",
      title: "Title",
      slide_title_req_1: "Slide 1 Title is required!",
      slide_title_req_2: "Slide 2 Title is required!",
      content: "Title",
      slide_content_req_1: "Slide 1 Message is required!",
      slide_content_req_2: "Slide 2 Message is required!",
      previous: "Previous",
      is_required: "is required",
      slide_required: "Schedule is required",
      date: "Date",
      select_all: "Select All",
      unselect_all: "Unselect All",
      odd_week: "Odd Week",
      even_week: "Even Week",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      share_with_others: "Share with others (public)",
      finish: "Finish",
      font_size: "Font Size",
      date_font: "Date Font",
      request: "Request",
      only_visible_to_friends: "Only Visible To Friends.",
      additional: "Additional",
      basic: "Basic",
    },
    TASK_MANAGEMENT: {
      today_only: "Today Only",
      today_forward: "Today and Forward",
      today_past: "Today and Past",
      archive: "Archive",
      user: "USERS",
      sub_user: "Sub User",
      task_status: "Task Status",
      summary: "Summary",
      show_in_tasks: "Show in tasks",
      show_in_calender: "Show in Calender",
      add_task: "Add a task",
      co_reporter: "Co-reporter",
    },
    CONTACT: {
      name: "Name",
      groupName: "Groupname",
      invoiceName: "Invoice name (OR full name)",
      invoice_name_error: "Invoice name is required",
      number: "Number",
      contact: "Contact",
      group: "Group",
      groups: "Groups",
      address: "Address",
      add: "Add",
      create: "Create",
      add_contact: "Add Contact",
      create_new: "Create New",
      name_Validation: "cannot be longer than 25 characters",
      description_Validation:
        "Group Description cannot be longer than 70 characters",
    },
    FOOTER: {
      changelog: "Changelog",
      documentation: "Documentation",
    },
    CATEGORIES: {
      move_up: "Move Up",
      move_down: "Move Down",
      delete: "Delete",
      new_folder: "New Folder",
      folder_management: "Folder Management",
      name_folder: "Name new folder",
    },
    GROUP_INVITATION: {
      group_invitation_text:
        "Enter the Email address of the person you want to invite, write a personal" +
        " greeting and select at the bottom what you want to invite. (Tip: You can invite more at one time by" +
        " entering multiple Email addresses)",
      message: "Message",
      send: "Send",
      invite_to: "Invite to",
      text: "Text",
      message_interval: "Message Font",
      or: "Or",
      personal_message: "Add Personal message (optional)",
      invite: "Invite",
    },
    GALLERY: {
      browser_not_supported: "Browser not supported",
      download: "Download",
      delete: "Delete",
      application: "Application",
      size: "Size",
      load_more: "Load More",
      image: "Image",
      actions: "Actions",
    },
    SETTINGS: {
      activate_pro: "Activate PRO",
      storage_settings: "Storage Settings",
      storage: "Storage",
      allow_large_files: "Allow Large Files",
      payment_profile: "Payment Profile PRO",
      number_already_exist: "Number Already Exist.",
      add_room: "Add Room",
      screen_width: "Screen Width",
      screen_height: "Screen Height",
      slide_delay_settings: "Slides Delay(Seconds)",
      animation: "Animation Speed(Seconds)",
      background_music: "Background Music",
      date_time: "Date & Time",
      add_user: "Add User",
      user_role: "User Role",
      yes: "Yes",
      register_card: "Register Card",
      no: "No",
      users: "Users",
      accept_to_delete_all: "I approve to permanently delete all content.",
      user: "User",
      keep_user_threads: "Keep User Threads?",
      this_field: "This Field",
      filter_users: "Filter Users",
      accept: "Accept",
      reject: "Reject",
      active: "Active",
      size: "Size",
      group_image: "Group Image",
      phone: "Phone",
      winter: "Winter",
      summer: "Summer",
      end_date: "End Date",
      start_date: "Start Date",
      spring: "Spring",
      fall: "Fall",
      show: "Show",
      record_time: "Record Time",
      patient_section: "Caller Section",
      room: "Room",
      section: "Section",
      call_patient: "Caller",
      video_call: "Video Call",
      width: "Width",
      patient_name: "Patient Name",
      play: "Play",
      time_interval: "Time interval",
      create_folder: "Create Folder",
      update_folder: "Update Folder",
      select_group: "Select Group",
      modules: "MODULES",
      group_settings: "GROUP SETTINGS",
      folder: "Folders",
      domain: "Domain",
      country: "Country",
      cvr_number: "CVR/SE Number",
      on: "ON",
      off: "OFF",
    },
    LOGIN: {
      login_placeholder:
        "The platform that will help you through the process of building a community or a team, " +
        " or you can use it as your personal space to collect, order and plan you digital stuff.",
      reactivate_account: "Reactivate Account",
      unlock: "Unlock",
      password: "Password",
      are_you_not: "Are you not",
      inactivity:
        "Due to inactivity, your session is locked. Enter your password to continue.",
      session_loked: "YOUR SESSION IS LOCKED",
      join_using:
        "Keep in touch with all your OnlineDoc stuff at the palm of your hand.",
      get_app: "Get the APP!",
      create_new_password: "CREATE NEW PASSWORD",
      save_my_password: "SAVE MY PASSWORD",
      reactivate_your_account: "REACTIVATE YOUR ACCOUNT",
      email_sent_to: "",
      resend_code_by_email: "Resend code by email",
      resend_code_by_sms: "Resend code via SMS",
      read_more_btn: "Read more",
    },
    PROFILES: {
      personal_information: "Personal Information",
      change_password: "Change Password",
      security: "Security",
      inactivity_limit: "Inactivity Limit",
      set_limit_before_logout: "Set minutes before logout",
      deactivate_account: "Deactivate Account",
      delete_account: "Delete Account",
      add_number: "Add Number",
      mobile_matching:
        "Mobile Number should not be matching other Mobile Numbers.",
      mobile_empty: "Mobile Number cannot be empty",
      invalid_phone: "Invalid Phone Number",
      number_greater: "Phone number cannot be greater than 10 digits.",
      phone_less: "Phone number cannot be less than 7 digits.",
      number_already: "Number already exists.",
      address_empty: "Address cannot be empty.",
      invalid: "Invalid",
      email_empty: "Email cannot be empty",
      email_exist: "Email already Exist",
      primary: "Primary",
      change: "Change",
      add_website: "Add Website",
      website_empty: "Websites cannot be empty",
      not_available: "Not Available",
      cancel_all: "Cancel All",
      basic_information: "Basic Information",
      profession: "Profession",
      friends: "Friends",
      requests: "Requests",
      joined_groups: "Joined Groups",
      current: "Current",
      new: "New",
      confirm_new: "Confirm New",
      must_match: "Must Match",
      friend_requests: "Friend Requests",
    },
    BROWSER_COMPATIBILITY: {
      browser_compatibility: "Browser Compatibility",
      browser_message:
        "Looks like you are using old browser, use Modern Browser or Google Chrome to view the complete features.",
    },
    VERSION_UPDATED: {
      new_version:
        "A newer version of OnlineDoc is available. Click YES to run newest version.",
      version: "Version Update",
    },
    SUSPENDED: {
      group_suspended: "Group suspended on",
      suspended_description: "Suspended due to missing payment.",
      contact_admin:
        "Please make your payment as soon as possible to prevent permanent data loss, or if you feel this\n" +
        '                to be an error, <a href="mailto:{{support@onlinedoc.dk}}">contact support.</a>',
      contact_member:
        'To prevent permanent data loss <a (click)="openSettingsBy()" id="contact_member">contact this groups SuperAdmin</a>\n' +
        "                for further information.",
      make_payment: "Make payment now",
      contact_super_admin: "Contact SuperAdmin",
    },
    STORAGE_SETTINGS: {
      storage_plan: "Storage Plan",
      current_plan_1: "You can change your Storage",
      current_plan_2: "Plan to meet your storage needs",
      current_plan_3_a: "Choose your",
      current_plan_3_b: "Group Plan",
      current_plan_3_c: "to meet",
      current_plan_4: "your needs in regards of",
      current_plan_5: "functions and modules",
      change_storage_plan: "Change storage plan",
      cancel_subscription: "Cancel Subscription",
      total_storage_space: "Total Storage Space",
      usage_by_type: "Usage By Type",
      type: "Type",
      videos: "Videos",
      images: "Images",
      changeImmediatley:
        "If you are downgrading in the middle of a paid period you will not be refunded any payment made for the active period.If you are upgrading in the middle of a period, you will be charged for the full period.",
      files: "Files",
      size: "Size",
      used_storage: "used_storage",
      available_storage: "available_storage",
      group_plans: "Group Plans",
      options: "Options",
      free: "FREE",
      switch_to: "SWITCH TO",
      active: "Active",
      current: "Current",
      free_storage_included: "10 GB storage included",
      timeline: "Timeline",
      file_explore: "File explore",
      task: "Task",
      calender: "Calendar",
      slideshow: "Slideshow",
      caller: "Caller",
      custom_integrations: "Custom integrations",
      third_part_software_ect: "(3rd part software ect.)",
      get_started: "GET STARTED",
      support_via_email_and_chat: "Support via Email & Chat",
      contact_sales: "Contact Sales",
      basic: "Basic",
      shift: "Shift",
      invoiceInformation: "Invoice information",
      private: "Private",
      corporate: "Corporate",
    },
    PAYMENT_INFO: {
      payment_profile: "Payment Profile",
      payment_profile_id: "Payment Profile Id",
      account_type: "Account Type",
      company_address: "Company name and address",
      corporation: "Corporation",
      not_available: "Not Available",
      profile_id_info:
        "The payment profile ID is the unique number we use to identify the legal entity responsible for the payment. The legal entity is the organization or individual registered with this payment account.",
      account_type_info:
        "Your payment profile and your country determine which fees are included in your collection and what types of documents you receive.",
      group_info:
        "This is the legally registered information, which is indicated on invoices.",
      payment_method: "Payment Method",
      discount_code: "Coupen Code",
      no_payment_method_found: "No card found",
      no_discount_code_found: "No discount code found",
      add_payment_method: "Add Payment Method",
      edit_payment_method: "Edit Payment Method",
      primary_payment_method: "Primary Payment Method",
      primary_discount_code: "Primary Discount Code",
      make_primary_payment_method: "Make Primary Payment Method",
      remove_payment_method: "Remove Payment Method",
      remove_discount_code: "Remove Discount Code",
      payment_summary_invoices: "Payment Summary (Invoices)",
      pdf_invoice: "PDF-invoice",
      csv_invoice: "CSV-invoice",
      no_invoices_found: "No invoices found",
    },
    loading: "loading",
    All: "All",
    Requests: "Requests",
    User: "Users",
    Profile: "Profile",
    "Slider Settings": "Slider Settings",
    "Caller Settings": "Caller Settings",
    "Group Backup": "Group Backup",
    "Slider Settings > Date & Time": "Slider Settings > Date & Time",
    "Slider Settings > Season Schedule": "Slider Settings > Season Schedule",
    "Caller Settings > Caller Section (Board)":
      "Caller Settings > Caller Section (Board)",
    "Caller Settings > Room Edit": "Caller Settings > Room Edit",
    "Caller Settings > Voice Call": "Caller Settings > Voice Call",
    "Caller Settings > Room Section": "Caller Settings > Room Section (Board)",
    "Select Group": "Select Group",
    "Caller Settings > Scroll Message": "Caller Settings > Scroll Message",
    "Caller Settings > Caller": "Caller Settings > Caller",
    "Caller Settings > Date Time": "Caller Settings > Date Time",
    IMPORTANT: "IMPORTANT",
    CRITICAL: "CRITICAL",
    NOTICE: "NOTICE",
    NORMAL: "NORMAL",
    "Today and Past": "Today and Past",
    "Today Only": "Today Only",
    "Today and Forward": "Today and Forward",
    "Delete ": "Delete ",
    "Are you sure you want to delete this group?":
      "Are you sure you want to delete this group?",
    "hours ago": "hours ago",
    "CREATE AN ACCOUNT": "CREATE AN ACCOUNT",
    "LOGIN TO YOUR ACCOUNT": "LOGIN TO YOUR ACCOUNT",
    "Full Name": "Full Name",
    Email: "Email",
    Phone: "Phone",
    Gender: "Gender",
    Password: "Password",
    "Password (Confirm)": "Password (Confirm)",
    Accept: "Accept",
    "terms and conditions": "terms and conditions",
    "Already have an account?": "Already have an account?",
    Login: "Login",
    Welcome: "Welcome",
    // 'LOGIN': 'LOG PÅ',
    "Welcome to the ONLINE": "Welcome to the ONLINE",
    "Remember Me": "Remember Me",
    "Forgot Password?": "Forgot Password?",
    Male: "Male",
    Female: "Female",
    "Havent received a code?":
      "Haven't received a code? (Remember to check your spam folder)",
    "Gender is required!": "Gender is required!",
    OR: "or",
    "RECOVER YOUR PASSWORD": "Recover your password",
    "Go back to login": "Go back to login",
    "SEND RESET LINK": "Send Reset Link",
    "Confirm your email address!": "Confirm your email address!",
    "A reset password e-mail has been sent to":
      "A reset password e-mail has been sent to",
    'Check your inbox and click on the "Confirm my email" link to confirm your email address.':
      'Check your inbox and click on the "Confirm my email" link to confirm your email address.',
    'Check your inbox and click on the "Reset Your Password" link to reset your account password.':
      'Check your inbox and click on the "Reset Your Password" link to reset your account password.',
    "Go back to login page": "Go back to login page",
    "A reset password e-mail has been sent.":
      "A reset password e-mail has been sent.",
    "Hey! Thank you for checking out our app.":
      "Hey! Thank you for checking out our app.",
    "Please validate your account details by insert code received in email/phone or click on link available in email.":
      "Please validate your account details by insert code received in email/phone or click on link available in email.",
    "Please insert confirmation code received from your email or phone:":
      "Please insert confirmation code received from your email or phone:",
    Code: "Code",
    "Code is required": "Code is required",
    "Please insert complete code": "Please insert complete code",
    "invalid or expired code": "invalid or expired code",
    Verify: "Verify",
    "If you would like to resend email please type your email below:":
      "If you would like to resend email please type your email below:",
    "RE SEND": "Resend",
    "Your Account is already confirmed.": "Your Account is already confirmed.",
    "A confirmation e - mail has been sent to":
      "A confirmation e-mail has been sent to",
    "You might need to check the spam folder.":
      "You might need to check the spam folder.",
    "The code has been send to": "The code has been send to",
    "Maximum numbers should be 8.": "Maximum numbers should be 8.",
    "Minimum numbers should be 8.": "Minimum numbers should be 8.",
    "Maximum inactivity limit is 1500.": "Maximum inactivity limit is 1500.",
    "Minimum inactivity limit is 5.": "Minimum inactivity limit is 5.",
    "Threads are showing on Dashboard": "Threads are showing on Dashboard",
    "Threads are hidden on Dashboard": "Threads are hidden on Dashboard",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",

    // error messages

    "Full Name is required": "Phone is requied",
    "Email is required": "Email is required",
    "Please enter a valid email address": "Please enter a valid email address",
    "Phone is required": "Phone is required",
    "Phone is invalid": "Phone is invalid",
    "Password is required": "Password is required",
    "Full Name is invalid": "Full Name is invalid",
    "Password confirmation is required": "Password confirmation is required",
    "Email already exists": "Email already exists",
    "Passwords must match": "Passwords must match",
    "Do not have an account?": "Do not have an account?",
    "Create an account": "Create an account",
    "Invalid username or password.": "Invalid username or password.",
    "Password should be at least 6 characters long.":
      "Password should be at least 6 characters long.",
    "No associated record with this email exists.":
      "No associated record with this email exists.",
    "Enter valid email address.": "Enter valid email address.",

    "First Name": "First Name",
    "Last Name": "Last Name",
    "Date of Birth": "Date of Birth",
    "Male/Female": "Male/Female",
    "Contact Number": "Contact Number",
    "Confirm Password": "Confirm Password",
    "Sign Up": "Sign Up",
    "User Name": "User Name",
    "Your Password": "Your Password",
    "Keep me logged in": "Keep me logged in",
    "Sign In": "Sign In",
    "Forgot Password": "Forgot Password",
    "You can reset your Password here": "You can reset your Password here",
    Send: "Send",
    "What type of user of you?": "What type of user of you??",
    "Doctor/ Health Staff/ Citizen": "Doctor/ Health Staff/ Citizen",
    Update: "Update",
    "What are you up to?": "What are you up to?",
    "Photos/Camera/Documents": "Photos/Camera/Documents",
    "Search Filter": "Search Filter",
    "Search in Files": "Search in Filter",
    "Search in Comments": "Search in comments",
    "Search in Threads": "Search in Threads",
    "Edit/ Delete": "Edit/ Delete",
    "Write a comment": "Write a comment",
    "Document List": "Document List",
    "Send Email": "Send Email",
    "Select Users": "Select Users",
    Subject: "Subject",
    "Write your message here": "Write your message here",
    "Find Users": "Find Users",
    "Search Users": "Search Users",
    "Selected Users": "Selected Users",
    "Attach Files": "Attach Files",
    Invite: "Invite",
    "Group Members": "Group Members",
    "Send Message": "Send Message",
    "Delete Member": "Delete Member",
    Settings: "Settings",
    Save: "Save",
    "Write your group description here": "Write your group description here",
    Privacy: "Privacy",
    Public: "Public",
    Hidden: "Hidden",
    "Folder (Categories)": "Folder (Categories)",
    "Category Name": "Category Name",
    "Add Category": "Add Category",
    "Delete Group": "Delete Group",
    "Send Invitation": "Send Invitation",
    "Invite to": "Invite to",
    "No user is registered with this email. Do you want to send invite to this email?":
      "No user is registered with this email. Do you want to send invite to this email?",
    "No/ Yes": "No / Yes",
    Chat: "Chat",
    Connections: "Connections",
    Other: "Other",
    "Day/Months/Year/Selected": "Day/Months/Year/Selected Period",
    "Few seconds/minutes/hours ago": "Few seconds/minutes/hours ago",
    "Accept Request/ Cancel": "Accept Request/ Cancel",
    "Create Thread": "Create Thread",
    "Thread Title": "Thread Title",
    "Thread description": "Thread description",
    "Add Users": "Add Users",
    "Explore Users/Groups": "Explore Users/Groups",
    "Awaiting Approval": "Awaiting Approval",
    "Go to Group": "Go to Group",
    "Create Group": "Create Group",
    "Group Title": "Group Title",
    "Next/Done": "Next/Done",
    "Select Categories": "Select Categories",
    "Create Categories": "Create Categories",
    "Can be managed and created late, user settings Categories are used in forums and file management, for the indvidual group or clinic.":
      "Can be managed and created late, user settings Categories are used in forums and file management, for the indvidual group or clinic.",
    "Logout/ Settings/ Invite": "Logout/ Settings/ Invite",
    "Contact Information": "Contact Information",
    "Basic Information": "Basic Information",
    "Website and Links": "Website and Links",
    Mobile: "Mobile",
    Address: "Address",
    "My Group History": "My Group History",
    "Thread History": "Thread History",
    "Read More": "Read More",
    "Account Settings": "Account Settings",
    "Change Password": "Change Password",
    "New Password": "New Password",
    "Update Password": "Update Password",
    "Inactivity Settings (Set Minutes before logout)":
      "Inactivity Settings (Set Minutes before logout)",
    Language: "Language",
    "Delete Account": "Delete Account",
    "Deactivate Account": "Deactivate Account",
    "Delete/ Disable": "Delete/ Disable",
    "Warning You are about to delete group and  all its content. When deleting this,  All data in group will be deleted and can NOT be restored!":
      "Warning You are about to delete group and  all its content. When deleting this,  All data in group will be deleted and can NOT be restored!",
    "I Confirm that I understand that all data will be deleted with NO  option to restore.":
      "I Confirm that I understand that all data will be deleted with NO  option to restore.",
    "What You want to Delete Remove and Delete all the files  from this user. Remove and Keep the files from this user.":
      "What You want to Delete Remove and Delete all the files  from this user. Remove and Keep the files from this user.",
    "Cancel/Okay": "Cancel/Okay",
    "Please Enter your Password": "Please Enter your Password",
    "User deleted Successfully": "User deleted Successfully",
    "Thread created Successfully": "Thread created Successfully",
    "Group created Successfully": "Group created Successfully",
    "Message sent Successfully": "Message sent Successfully",
    "Message deleted Successfully": "Message deleted Successfully",
    "Thread deleted Successfully": "Thread deleted Successfully",
    "Thread updated Successfully": "Thread updated Successfully",
    "Comment sent Successfully": "Comment sent Successfully",
    "Comment deleted": "Comment deleted",
    "Comment updated": "Comment updated",
    "Invitation sent Successfully": "Invitation sent Successfully",
    "Friend request sent Successfully": "Friend request sent Successfully",
    "Request canceled": "Request canceled",
    "Membership request sent": "Membership request sent",
    "Membership request canceled": "Membership request canceled",
    "Group mail sent Successfully": "Group mail sent Successfully",
    "Invalid username or password": "Invalid username or password",
    "Please check your email for Account Confirmation":
      "Please check your email for Account Confirmation",
    "Additional Information": "Additional Information",
    "Long Press for Delete Link": "Long Press for Delete Link",
    "Please enter valid URL": "Please enter valid URL",
    "User can add further description about himself":
      "User can add further description about himself",
    "Please Enter your Email and Password":
      "Please Enter your Email and Password",
    "Please fill all the fields": "Please fill all the fields",
    "Password does not match": "Password does not match",
    "You are not connected with internet":
      "You are not connected with internet",
    "User deactivated Successfully": "User deactivated Successfully",
    "This user is deactivated, click here here to send activation mail":
      "This user is deactivated, click here here to send activation mail",
    "Who can add files?": "Who can add files?",
    "All members can/ Only I can": "All members can/ Only I can",
    "Recipients of thread": "Recipients of thread",
    "Who can approve new members": "Who can approve new members",
    "All members/ Only Admin": "All members/ Only Admin",
    "Who can send group email": "Who can send group email",
    "Folders (Categories)": "Folder (Categories)",
    "DELETE MEMBER": "Delete Member",
    "Group Info Update": "Group Info Update",
    "Group Image Update": "Group Image Update",
    "Group Request": "Group Request",
    "Update Message": "Update Message",
    "Delete Message": "Delete Message",
    "Group Email": "Group Email",
    "Email Subject": "Email Subject",
    "Email Message": "Email Message",
    "GROUP REQUEST": "Group Request",
    "Delete Thread": "Delete Thread",
    "File Save Error": "File Save Error!",
    "Saved!": "Saved!",
    "File Download": "File Download",
    "Edit Thread": "Edit Thread",
    "Change Notification Setting": "Change Notification Setting",
    "Updated successfully.": "Updated successfully.",
    "Group image uploaded successfully": "Group image uploaded successfully",
    "Group Title is Required": "Group Title is Required",
    "Request status has been updated.": "Request status has been updated.",
    "Message deleted successfully": "Message deleted successfully",
    "Message updated successfully": "Message updated successfully",
    "Email send successfully.": "Email send successfully.",
    "Please provide Email Subject": "Please provide Email Subject",
    "Please provide email message": "Please provide email message",
    "You have successfully unsubscribe.": "You have successfully unsubscribe.",
    "User Deleted successfully.": "User Deleted successfully",
    "Document deleted successfully": "Document deleted successfully",
    "Your requested file has been saved to your device":
      "Your requested file has been saved to your device",
    "Successfully downloaded.": "Successfully downloaded.",
    "Group deleted successfully.": "Group deleted successfully.",
    "Notification update successfully": "Notification update successfully",
    "Thread details are updated successfully":
      "Thread details are updated successfully",
    "Do you want to create new thread?": "Do you want to create new thread?",
    "... Show More": "... Show More",
    " Show Less": " Show Less",
    Comments: "Comments",
    Files: "Files",
    "User Groups": "User Groups",
    "Requested Groups": "Requested Groups",
    "Other Groups": "Other Groups",
    "Other Users": "Other Users",
    "If you click on Sign Up, you accept our terms, readmore about how we collect, use and share your information, in our data policy and on how we use cookiesand similar technology, in our cookies policy. You may recieve SMS notification from us and may cancel them at any time.":
      "If you click on Sign Up, you accept our terms, readmore about how we collect, use and share your information, in our data policy and on how we use cookiesand similar technology, in our cookies policy. You may recieve SMS notification from us and may cancel them at any time.",
    "What type of user are you?": "What type of user are you?",
    "FORGOT PASSWORD?": "Forgot Password?",
    "You can reset your password here.": "You can reset your password here.",
    "Do you want to send invitation to this e-mail":
      "Do you want to send invitation to this e-mail",
    Description: "Description",
    "Description of the group here": "Description of the group here",
    "Who can send group mail?": "Who can send group mail?",
    "Select Recipients": "Select Recipients",
    "Group posts and members will be visible to all members":
      "Group posts and members will be visible to all members.",
    Name: "Name",
    "Group posts and members will be hidden to all members":
      "Group posts and members will be hidden to all members.",
    "SEARCH FILTERS": "Search Filters",
    "Group Name": "Group Name",
    "ADD CATEGORIES": "Add Categories",
    "SELECT RECIPIENTS": "Select Recipients",
    "Write a note...": "Write a note...",
    "No user is registered with this e-mail.":
      "No user is registered with this e-mail.",
    Recipients: "Recipients",
    Closed: "Closed",
    "THREAD DETAILS": "Thread Details",
    PROFILE: "Profile",
    "Phone Number": "Phone Number",
    Danish: "Danish",
    English: "English",
    "My group and thread history": "My group and thread history",
    "Confirm New password": "Confirm New password",
    Profession: "Profession",
    "Add additional info here...": "Add additional info here...",
    "Minutes for auto logout": "Minutes for auto logout",
    "My Thread History": "My Thread History",
    "Web Links": "Web Links",
    "Inactivity Setting": "Inactivity Setting",
    "additional Info Here if any...": "additional Info Here if any...",
    "Categories are used in forums and file management, for the individual group or clinic":
      "Categories are used in forums and file management, for the individual group or clinic.",
    "Group Description...": "Group Description.....",
    "ADD NEW CATEGORY": "Add new category",
    "Can be manage and creates later, under settings":
      "Can be manage and creates later, under settings",
    "create thread flow": "create thread flow",
    "Who can Add files?": "Who can Add files?",
    "Send also posting some e-mail notifications to members":
      "Send also posting some e-mail notifications to members",
    PERMISSIONS: "Permissions",
    "All Readers Can": "All Readers Can",
    Doctors: "Doctors",
    Priority: "Priority",
    "E-mail notification": "Email notification",
    "Write a Message": "Write a Message",
    Others: "Others",
    CategoryFieldCell: "CategoryFieldCell",
    "Name a Category": "Name a Category",
    "ChatViewCell:": "ChatViewCell",
    CommentText: "CommentText",
    CommentDetailViewCell: "CommentDetailViewCell",
    "Here goes the message": "Here goes the message",
    "days ago": "days ago",
    ConnectionViewCell: "ConnectionViewCell",
    'Hello there! I"m using Inline Doc': 'Hello there! I"m using Inline Doc',
    GroupViewCell: "GroupViewCell",
    LinearRowViewCell: "LinearRowViewCell",
    "Standard (Default)": "Standard (Default)",
    MemberInfoTableViewCell: "MemberInfoTableViewCell",
    "SEND MESSAGE": "Send Message",
    Next: "Next",
    Back: "Back",
    "Profile Update": "Profile Update",
    "Account updated Successfully": "Account updated Successfully",
    "Group Creation": "Group Creation",
    "Group created successfully": "Group created successfully",
    "Auto Log Out": "Auto Log Out",
    "Due to inactivity you have been logged out from the current session":
      "Due to inactivity you have been logged out from the current session",
    "Group Description": "Group Description",
    "Write a message": "Write a message..",
    "write a comment": "write a comment..",
    "Validation Failure": "Validation Failure",
    "Reset Password": "Reset Password",
    "are required": "are required",
    "is required": "is required",
    "is not a valid Email": "is not a valid Email",
    "Password and Confirm Password are not Same":
      "Password and Confirm Password are not Same",
    "User Sign Up Success": "User Sign Up Success",
    "Password should be at least 6 characters long":
      "Password should be at least 6 characters long",
    "Confirm Password should be at least 6 characters long":
      "Confirm Password should be at least 6 characters long",
    "Valid Email Required": "Valid Email Required",
    "To reset password Please provide a valid Email":
      "To reset password Please provide a valid Email",
    'Registered Email is required and can"t be empty.':
      'Registered Email is required and can"t be empty.',
    'Password is required and can"t be empty':
      'Password is required and can"t be empty',
    "Friend Request": "Friend Request",
    "Membership request sent successfully":
      "Membership request sent successfully",
    "Account confirmation is required. Please, check your email for the confirmation link":
      "Account confirmation is required. Please, check your email for the confirmation link",
    "If there is an account associated with #MAIL# you will receive an email with a link to reset your password":
      "Hvis der er en konto forbundet med [MAIL], modtager du en e -mail med et link til at nulstille din adgangskodeIf there is an account associated with #MAIL# you will receive an email with a link to reset your password",
    year: "year",
    month: "month",
    day: "day",
    hour: "hour",
    minute: "minute",
    second: "second",
    months: "months",
    days: "days",
    hours: "hours",
    minutes: "minutes",
    seconds: "seconds",
    "a moment": "a moment",
    ago: "ago",
    "Add additional info here": "Add additional info here",
    "Selected Recipients": "Selected Recipients",
    "Inactivity Time Update": "Inactivity Time Update",
    "Inactivity Time limit Updated successfully":
      "Inactivity Time limit Updated successfully",
    "Contact information": "Contact information",
    "Document Added Successfully": "Document Added Successfully",
    "Yes/No": "Yes/No",
    "Do you want to create another?": "Do you want to create another?",
    "Which Type of user are you?": "Which Type of user are you?",
    "Delete Profile": "Delete Profile",
    "Are you sure you want to delete?": "Are you sure you want to delete?",
    "New Password and Confirm new password are required":
      "New Password and Confirm new password are required",
    "Current password is required": "Current password is required",
    "Confirm new password is required": "Confirm new password is required",
    "Your Account is deactivated. Please confirm your account":
      "Your Account is deactivated. Please confirm your account",
    "Resend Confirmation": "Resend Confirmation",
    "Your account is permanent deleted. Please contact admin":
      "Your account is permanent deleted. Please contact admin",
    "Account confirmation": "Account confirmation",
    "This account is not confirmed": "This account is not confirmed",
    "Confirmation link": "Confirmation link",
    "You Are trying to delete #GROUP. This is not possible as long as other users are a member of this.":
      "You Are trying to delete #GROUP. This is not possible as long as other users are a member of this.",
    "If you delete a category / folder, all content will be deleted and can NOT be recreated. Alternatively, you can disable the category / folder by unchecking it in front of it in the list.":
      "If you delete a category / folder, all content will be deleted and can NOT be recreated. Alternatively, you can disable the category / folder by unchecking it in front of it in the list.",
    "I agree that all content will be deleted":
      "I agree that all content will be deleted",
    Contacts: "Contacts",
    "Search Contacts": "Search Contacts",
    Info: "Info",
    Type: "Type",
    Size: "Size",
    Location: "Location",
    Edited: "Edited",
    Created: "Created",
    Overview: "Overview",
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
    Total: "Total",
    Select: "Select",
    "Copy to next week": "Copy to next week",
    "Copy to rest of the year": "Copy to rest of the year",
    "Do you want to mark": "Do you want to mark",
    "as a": "as a",
    "Do you want to clear this status?": "Do you want to clear this status?",
    Sick: "Sick",
    Holiday: "Holiday",
    "On Leave": "On Leave",
    "Day Off": "Day Off",
    "is marked as": "is marked as",
    "Change Status": "Change Status",
    "Clear Status": "Clear Status",
    "Create Working Hours": "Create Working Hours",

    "You are about to create working hours for next year. How would you like to proceed?":
      "You are about to create working hours for next year. How would you like to proceed?",

    "Copy current year": "Copy current year",
    "Create with empty data": "Create with empty data",
    "Gathering data. Please wait..": "Gathering data. Please wait..",
    Yes: "Yes",
    Cancel: "Cancel",
    Insert: "Insert",
    "Currently checked in": "Currently checked in",
    "Currently checked out": "Currently checked out",
    Page: "Page",
    Done: "Done",
    // error messages
  },
};
