import { Injectable } from "@angular/core";

export interface GroupCategory
{
    id: number;
    groupId: number;
    categoryName: string;
    sortOrder: number;
    isActive: boolean;
    isAvailable: boolean;
    threadCount: number;
    categoryNotificationCount: number;
    updatedAt: any;
    createdAt: any;
    size: any;
    isHidden: boolean;
    isDefault: boolean;
    clinicContact: any | boolean;
    activity_posts_folder:boolean
}

export interface GroupObj {
  id: number;
  name: string;
  isPublic: boolean;
  description: string;
  memberCount: number;
  file: object;
  requestStatus: string;
}

export interface GroupUser {
  id: number;
  userId: number;
  clinic_role: string;
  role: string;
  request_status: string;
  fullName: string;
  selected?: boolean;
  checkedIn: boolean;
  checkInTime: string;
}

export interface GroupAdmin {
  id: number;
  role: string;
  firstName: string;
  lastName: string;
}

export interface GroupFiles {
  id: number;
  type: string;
  name: string;
  owner: string;
  size: number;
  modified: any;
  contentType: string;
  date: any;
  thumbUrl: string;
  signedId: string;
  threadId: number;
  userID: number;
  src: string;
  mediumUrl: string;
  commentsCount: number;
}

export interface GroupQRCode {
  clinic_id: number;
  content_type: string;
  date: string;
  name: string;
  signed_id: string;
  size: number;
  src: string;
  download_url: string;
}

export interface IGroup {
  clinic: any;
  id: number;
  name: string;
  invoiceName: string;
  domain: string;
  country: string;
  cvrNumber: string;
  description: string;
  isPublic: boolean;
  invitationSentBy: string;
  approvedBy: string;
  attachment: any;
  email: string;
  address: string;
  phone: string;
  additionalPhone: string;
  fax: string;
  city: string;
  zipCode: string;
  joinedMembers: number;
  requestedMembers: string;
  canSendInvite: string; // admin
  canApprove: string; // admin
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  clinicAdmins: any[];
  clinicNotificationCount: number;
  clinicNotification: boolean;
  displayTimeline: boolean;
  categories: GroupCategory[];
  users: GroupUser[] | null;
  clinicModules: any;
  file: object;
  patientCallerSettings: any;
  slideShowSettings: any;
  filteredUsers: any;
  selectedRoomId: any;
  isDefault: boolean;
  canLeaveClinic: boolean;
  suspended: boolean;
  suspendedDate: string;
  planToBePurchased?: number;
  paymentId?: number;
  fileUsage: number;
  paymentProfileId: number;
  currentPlan?: any;
  paymentMethods: any;
  subscriptions: any;
  signed_id?: number;
  totalBytes: number;
  isCorporate: boolean;
  groupPinned: boolean;
  performImmediately: string;
  isInitialGroup: boolean;
  allowSharing: boolean;
  hasBackup: boolean;
  backupSize: number;
  lastBackupDate: string;
  registered_discount_code: any;
  qr_code?: GroupQRCode;
}

@Injectable({
  providedIn: "root",
})
export class GroupUserAdapter {
  adapt(item: any): GroupUser {
    return {
      id: item.id,
      clinic_role: item.clinic_role,
      fullName: item.full_name,
      request_status: item.request_status,
      role: item.role,
      userId: item.user_id,
      checkedIn: item.checked_in,
      checkInTime: item.check_in_time,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class GroupAdapter {
  adapt(item: any): IGroup {
    const adminAdapter = new GroupAdminAdapter();
    return {
      allowSharing: item.allow_sharing,
      performImmediately: item.perform_immediately,
      groupPinned: item.group_pinned,
      totalBytes: item.total_bytes,
      isCorporate: item.is_corporate,
      city: item.city,
      zipCode: item.zip_code,
      suspended: item.is_suspended,
      suspendedDate: item.suspended_date,
      invoiceName: item.invoice_name,
      paymentProfileId: item.stripe_customer_id,
      fileUsage: item.file_usage,
      subscriptions: item.subscriptions,
      paymentMethods: item.payment_methods,
      country: item.country,
      cvrNumber: item.cvr_or_se,
      domain: item.domain,
      clinic: item.clinic,
      clinicModules: item.clinic_module,
      clinicNotification: item.is_notification_clinic,
      clinicNotificationCount: item.clinic_notifications_count,
      displayTimeline: item.is_display_timeline,
      clinicAdmins: item.clinic_admins
        ? item.clinic_admins.map((data) => adminAdapter.adapt(data))
        : [],
      joinedMembers: item.joined_members,
      requestedMembers: item.requested_members,
      additionalPhone: item.additional_phone,
      address: item.address,
      canApprove: item.can_approve,
      canSendInvite: item.can_send_invite,
      createdAt: item.created_at,
      email: item.email,
      fax: item.fax,
      isActive: item.is_active,
      phone: item.phone,
      updatedAt: item.updated_at,
      id: item.id,
      name: item.name,
      description: item.description,
      isPublic: item.is_public,
      invitationSentBy: "",
      approvedBy: "",
      attachment: item.image || null,
      categories: item.clinic_categories
        ? item.clinic_categories.map((data) => GroupCategoryAdapter.adapt(data))
        : [],
      users: null,
      file: item.file,
      filteredUsers: item.filtered_users || [],
      patientCallerSettings: null,
      slideShowSettings: null,
      selectedRoomId: item.selected_room_id,
      isDefault: item.is_default,
      canLeaveClinic: item.can_leave_clinic,
      isInitialGroup: item.is_initial_group,
      hasBackup: item.has_backup,
      backupSize: item.backup_size || 0,
      lastBackupDate: item.last_backup_date,
      registered_discount_code: item.registered_discount_code,
      qr_code: item.qr_code,
    };
  }

  encode(item: Partial<IGroup>): any {
    return {
      allow_sharing: item.allowSharing,
      city: item.city,
      zip_code: item.zipCode,
      name: item.name,
      invoice_name: item.invoiceName,
      country: item.country,
      cvr_or_se: item.cvrNumber,
      description: item.description,
      can_send_invite: item.invitationSentBy,
      can_approve: item.approvedBy,
      is_public: item.isPublic,
      clinic_categories_attributes: item.categories,
      fax: item.fax,
      address: item.address,
      phone: item.phone,
      file: item.file,
      selected_room_id: item.selectedRoomId,
      is_corporate: item.isCorporate,
      group_pinned: item.groupPinned,
      perform_immediately: item.performImmediately,
    };
  }
}

export class GroupObjAdapter {
  public adapt(item: any): GroupObj {
    return {
      id: item.id,
      isPublic: item.is_public,
      name: item.name,
      description: item.description,
      memberCount: item.member_count,
      file: item.file,
      requestStatus: item.request_status,
    };
  }
}

export class GroupCategoryAdapter
{
    public static adapt(item: any): GroupCategory
    {
        return {
            id                       : item.id,
            groupId                  : item.clinic_id,
            categoryName             : item.name,
            sortOrder                : item.sort_order,
            isActive                 : item.is_active,
            threadCount              : item.total_doc_count,
            isAvailable              : item.is_available,
            categoryNotificationCount: item.category_notification_count,
            updatedAt                : item.updated_at,
            createdAt                : item.created_at,
            size                     : item.size,
            isHidden                 : item.is_hidden,
            isDefault                : item.is_default,
            clinicContact            : item.clinic_contact || false,
            activity_posts_folder    : item.activity_posts_folder
        };
    }
}

export class GroupAdminAdapter {
  public adapt(item: any): GroupAdmin {
    return {
      id: item.id,
      lastName: item.last_name,
      firstName: item.first_name,
      role: item.role,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class GroupFilesAdapter {
  public adapt(item: any): GroupFiles {
    return {
      id: item.id,
      type: item.content_type,
      name: item.name,
      owner: item.user.full_name,
      size: item.size,
      modified: item.date,
      contentType: item.content_type,
      date: item.date,
      thumbUrl: item.thumb_url,
      signedId: item.signed_id,
      threadId: item.thread_id,
      userID: item.user.id,
      src: item.src,
      mediumUrl: item.medium_url,
      commentsCount: item.thread_comments_count,
    };
  }
}
