import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NewAuthDataService } from "../../../../service/api/newAuth-data.service";
import { ThreadsEntityService } from "../../../../store/thread/threads-entity.service";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { ToastrService } from "ngx-toastr";
import { ThreadsDataService } from "../../../../store/thread/threads-data.service";
import { ThreadDialogComponent } from "../dialogs/thread/thread.component";
import { MatDialog } from "@angular/material/dialog";
import { FooterType, User } from "app/types";
import { TaskComponent } from "../dialogs/task/task.component";
import { TaskDataService } from "../../../../store/task/task-data.service";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dailog/confirmation-dialog.component";
import { DocReadEntityService } from "../../../../store/doc-read/doc-read-entity.service";
import { IDocRead, ReadType } from "../../../../store/doc-read/doc-read.model";
import { ShareThreadDialogComponent } from "../dialogs/share-thread-dialog/share-thread-dialog.component";
import { Thread } from "../../../../store/thread/thread.model";
import { GroupsDataService } from "../../../../store/group/groups-data.service";
import { UploadingFilesDataService } from "../../../../store/uploading-files/uploading-files-data.service";
import { Subscription } from "rxjs";
import { EditChecktimeDialogComponent } from "../dialogs/edit-checktime-dialog/edit-checktime-dialog.component";
import * as moment from "moment";
import { IGroup } from "app/store/group/group.model";

@Component({
  selector: "post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PostComponent implements OnInit, OnDestroy, AfterViewInit {
  FooterType = FooterType;
  @Input() post: Thread | any;
  @Input() currentClinic?: IGroup | any;
  @Input() threads:Thread[] | any;
  currentUser: User;
  threadAuthor: boolean;
  showFooterType: FooterType = FooterType.Comment;
  postForm = new FormGroup({
    notifications: new FormControl(""),
  });
  newPost: any;
  readType: typeof ReadType = ReadType;
  sharedThreadId: number;
  taskStart: any;
  taskEnd: any;
  latitude: any;
  longitude: any;
  isAdmin: boolean;
  private isFileUploadingSubscription: Subscription;

  constructor(
    private authService: NewAuthDataService,
    private threadEntity: ThreadsEntityService,
    private progressBarService: FuseProgressBarService,
    private toast: ToastrService,
    private threadDataService: ThreadsDataService,
    private _matDialog: MatDialog,
    private taskDataService: TaskDataService,
    private groupDataService: GroupsDataService,
    private docReadEntityService: DocReadEntityService,
    private cdRef: ChangeDetectorRef,
    private fileUploadingDataService: UploadingFilesDataService
  ) {
    this.isFileUploadingSubscription = this.fileUploadingDataService
      .checkFileBeingUploaded()
      .subscribe((data) => {
        if (
          "isFileBeingUploaded" in this.post &&
          this.post.id === data.postId
        ) {
          this.post.isFileBeingUploaded = "state" in data ? data.state : data;
        }
      });
  }

  ngOnInit(): void {
    if ("docReads" in this.post) {
      const docReadList: IDocRead[] = [];
      for (const item of this.post.docReads) {
        const tempRead: IDocRead = {
          id: item.id,
          itemId: this.post.id,
          user: item.user,
          type: ReadType.doc,
        };
        docReadList.push(tempRead);
      }
      this.docReadEntityService.addManyToCache(docReadList);
    }
    this.authService.getUser().subscribe((data) => {
      this.currentUser = data;
    });
    this.threadAuthor = this.currentUser.id === this.post.docCreator?.id;
    if (this.currentClinic) {
      const isAdminObj = this.currentClinic?.clinicAdmins.find((item) => {
        return +item.id === +this.currentUser.id;
      });
      this.isAdmin = isAdminObj === undefined ? false : true;
    }
    this.groupDataService.getShareThreadId().subscribe((data) => {
      this.sharedThreadId = data?.threadId;
    });
    this.taskStart = moment(this.post?.taskStart).format("HH:mm");
    this.taskEnd = moment(this.post?.taskEnd).format("HH:mm");
  }

  pinThreadInChat(): void {
    this.progressBarService.show();
    this.threadDataService.pinThreadToChat(this.post.id).subscribe({
      next: (res) => {
        this.post.postPinned = !this.post.postPinned;
        this.progressBarService.hide();
        this.toast.success("", res.message);
      },
      error: (err) => {
        this.progressBarService.hide();
        this.toast.error(err.error, "Error");
      },
    });
  }

  /**
   * On AfterViewInit
   */
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  notificationsStatus(status: any): void {
    this.postForm.patchValue({
      notifications: status,
    });
  }

  setFooterType(type: FooterType): void {
    this.showFooterType = type;
  }

  turnThreadNotificationOn(): void {
    this.progressBarService.show();
    const clinicData = {
      doc_notification: {
        clinic_id: this.post.clinicId,
        doc_id: this.post.id,
        notification: true,
      },
    };
    this.threadDataService.threadNotificationToggle(clinicData).subscribe(
      (data) => {
        this.toast.success("", data.message);
        this.progressBarService.hide();
        this.post.docNotification = true;
        this.threadEntity.updateOneInCache({
          docNotification: true,
          id: this.post.id,
        });
      },
      (error) => {
        this.progressBarService.hide();
        this.toast.error(error.error, "Error");
      }
    );
  }

  turnThreadNotificationOff(): void {
    this.progressBarService.show();
    const clinicData = {
      doc_notification: {
        clinic_id: this.post.clinicId,
        doc_id: this.post.id,
        notification: false,
      },
    };
    this.threadDataService.threadNotificationToggle(clinicData).subscribe(
      (data) => {
        this.toast.success("", data.message);
        this.progressBarService.hide();
        this.post.docNotification = false;
        this.threadEntity.updateOneInCache({
          docNotification: false,
          id: this.post.id,
        });
      },
      (error) => {
        this.progressBarService.hide();
        this.toast.error(error.error, "Error");
      }
    );
  }

  editThread(): void {
    this._matDialog.open(ThreadDialogComponent, {
      panelClass: "card-dialog",
      data: {
        new: false,
        threadData: this.post,
        contact: this.post?.clinicCategory?.clinic_contact,
      },
    });
  }

  shareThread(): void {
    this._matDialog.open(ShareThreadDialogComponent, {
      panelClass: "thread-share",
      data: {
        post: this.post,
      },
    });
  }

  deleteThread(): void {
    this._matDialog
      .open(ConfirmationDialogComponent, {
        panelClass: "confirmation-dialog",
        data: {
          title: "Delete Thread",
          message: "Are you sure you want to delete this thread?",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res[0] === "yes") {
          this.progressBarService.show();
          this.threadDataService.deleteThread(this.post.id).subscribe(
            (data) => {
              this.toast.success("", data.message);
              this.threadEntity.delete(this.post.id);
              this.progressBarService.hide();
            },
            (error) => {
              this.progressBarService.hide();
              this.toast.error(error.error, "Error");
            }
          );
        }
      });
  }

  editTask(task): void {
    this.taskDataService.getTaskByID(task.id).subscribe((data) => {
      this._matDialog.open(TaskComponent, {
        panelClass: "task-card-dialog",
        data: {
          new: false,
          taskData: data,
        },
      });
    });
  }

  isFilesUploading(data): void {
    if ("isFileBeingUploaded" in this.post) {
      this.post.isFileBeingUploaded = data;
    }
  }

  onAppear(): void {
    this.threadDataService.viewedDocIds.push(this.post.id);
    this.threadDataService.docReadsRequests.next({ doc_id: this.post.id });
  }

  editTime(post: Thread, checkTime: any, startOrEnd) {
    if (!this.isAdmin) {
      return;
    }
    let checkInTimeObj;
    if (this.post?.taskEnd) {
       checkInTimeObj = this.threads?.find((thread) => thread.id === this.post.id && thread.taskStart !== undefined);
    }
    const dialogRef = this._matDialog.open(EditChecktimeDialogComponent, {
      panelClass: "card-dialog",
      data: {
        post,
        time: checkTime,
        startOrEnd,
        ...(this.post?.taskEnd && {
          CheckInTime: checkInTimeObj?.taskStart,
        }),
      },
    });
  }

  openMap() {
    if (!this.isAdmin) {
      return;
    }
    this.taskDataService.getLocation().subscribe({
      next: (position) => {
        this.latitude = position.coords?.latitude;
        this.longitude = position?.coords?.longitude;
        this.openGoogleMaps(this.latitude, this.longitude);
      },
      error: (err) => {
        this.latitude = null;
        this.longitude = null;
        this.toast.error(err.error, "Error");
      },
    });
  }

  openCardDialog(task): void {
    this.taskDataService.getTaskByID(task.id).subscribe((data) => {
      this._matDialog.open(TaskComponent, {
        panelClass: "task-card-dialog",
        data: {
          type: "task",
          new: false,
          taskData: data,
          module: 'time_tracker',
          isGroupTimeline:true
        },
      });
    })
  }

  openGoogleMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  }

  getStyle(post: Thread): string {
    if (post?.docType !== "time_tracker" ) return "";
    return post.taskStart && !post.taskEnd
      ?  post.manual_check_in ?  "#9999ca" : "#AAECC5"
      : post.taskEnd
      ? post.manual_check_in ?  "#646492" : "#999999"
      : "";
  }
}
