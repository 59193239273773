<div class="header mat-elevation-z4 primary" fxLayout="row" fxLayoutAlign="space-between center">

    <ng-container *ngIf="selectedContact === null">

        <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center"
             (click)="unfoldSidebarTemporarily()">
            <mat-icon class="s-32 fuse-white-fg">chat</mat-icon>
            <h3 class="ml-12">{{'SETTINGS.users' | translate}}</h3>
        </div>

    </ng-container>

    <ng-container *ngIf="selectedContact !== null">

        <div [userProfile]="selectedContact?.user?.id" class="title cursor-pointer" fxLayout="row"
             fxLayoutAlign="start center">
            <img [src]="selectedContact?.user?.file? selectedContact?.user?.file.thumb_url: 'assets/images/avatars/profile.jpg'"
                 class="avatar mx-16">
            <h3 class="text-truncate">{{selectedContact?.user?.full_name}}</h3>
        </div>

    </ng-container>

    <button mat-icon-button class="toggle-sidebar-folded mr-8" (click)="foldSidebarTemporarily();resetChat();"
            fxHide fxShow.gt-md tabindex="-1">
        <mat-icon class="secondary-text s-20">close</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="toggleSidebarOpen();resetChat();"
            fxHide.gt-md tabindex="-1">
        <mat-icon class="secondary-text">close</mat-icon>
    </button>

</div>

<div class="content">

    <!-- Contacts -->
    <div id="contacts-list" fusePerfectScrollbar
         [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDisabled]="holdingScroll"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="0"
         (scrolled)="onScrollDown()">
        <!--        [ngClass]="contact.status"-->
        <div *ngFor="let contact of contacts; let index = index;"
             class="contacts-list-item"

             [class.active]="contact.user?.id === selectedContact?.user?.id"
             (click)="toggleChat(contact)">

            <img class="avatar"
                 [src]="contact.user?.file? contact.user?.file.thumb_url: 'assets/images/avatars/profile.jpg'"
                 [matTooltip]="contact.user?.full_name"
                 matTooltipPosition="left">
            <div class="unread-count"
                 *ngIf="contact.messageCount && contact.messageCount>0">{{contact.messageCount}}</div>
            <div class="status-icon" [ngClass]="contact.user?.online_status"></div>

        </div>

    </div>
    <!-- / Contacts -->

    <!-- Chat -->
    <div id="chat" fxLayout="column" fxFlex="1 1 auto">

        <div id="messages" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>

            <ng-container *ngIf="chat && chat.dialog && chat.dialog.length > 0">

                <div *ngFor="let message of chat.dialog; let i = index" class="message-row"
                     [ngClass]="{
                            'me': message.who === user.id,
                            'contact': message.who !== user.id,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }">

                    <img *ngIf="shouldShowContactAvatar(message, i)"
                         [src]="selectedContact.user?.file? selectedContact.user?.file.thumb_url: 'assets/images/avatars/profile.jpg'"
                         class="avatar">

                    <div class="bubble" *ngIf="message.message_type !== 'file'">
                        <!--                        <div class="message">{{message.message}}</div>-->
                        <div class="message">
                            <app-linkify-read-more [limit]="250" [content]="message?.message"></app-linkify-read-more>
                        </div>
                        <div class="time secondary-text">
                            <mat-icon class="s-16 mr-4"  [ngStyle]="{'color': message.receiverIsRead ? '#2F9BE5': '', 'margin-top' : '3px'}" *ngIf="message.who === user.id" [matMenuTriggerFor]="messageMenu">
                                done_all
                            </mat-icon>
                            <div class="secondary-text">{{message.time | date:'dd/MM/yy HH:mm'}}
                                <mat-icon id="chatAction" class="s-16" *ngIf="message.who === user.id" [matMenuTriggerFor]="messageMenu">
                                    more_vert
                                </mat-icon>
                                <mat-menu #messageMenu="matMenu" id="chatActionMenu">
                                    <button id="chatActionEdit" mat-menu-item (click)="editMessage(message)">
                                        {{'DASHBOARD_TASK.edit' | translate}}
                                    </button>
                                    <button id="chatActionDelete" mat-menu-item (click)="deleteMessage(message,i)">
                                        {{'DASHBOARD_TASK.delete' | translate}}
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="bubble w-100-p" *ngIf="message.message_type === 'file'">
                        <div class="time secondary-text">{{message.time | date:'dd/MM/yy HH:mm'}}
                            <mat-icon id="chatFileAction" class="s-16" *ngIf="message.who === user.id" [matMenuTriggerFor]="messageMenu">
                                more_vert
                            </mat-icon>
                            <mat-menu id="chatFileActionMenu" #messageMenu="matMenu">
                                <button id="chatFileEdit"  mat-menu-item (click)="editMessage(message)">
                                    {{'DASHBOARD_TASK.edit' | translate}}
                                </button>
                                <button id="chatFileDelete" mat-menu-item (click)="deleteMessage(message,i)">
                                    {{'DASHBOARD_TASK.delete' | translate}}
                                </button>
                            </mat-menu>
                        </div>
                        <div class="chat-img w-100-p">
                            <app-gallery [items]="message.files" size="small"></app-gallery>
                        </div>
                    </div>

                </div>

            </ng-container>

            <ng-container *ngIf="selectedContact && chat && chat.dialog && chat.dialog.length === 0">

                <div class="no-messages-icon">
                    <mat-icon class="s-128 fade-text">chat</mat-icon>
                </div>

                <div class="no-messages secondary-text">
                    {{'CHAT.start_conversation_by_typing' | translate}}
                </div>

            </ng-container>

            <ng-container *ngIf="!selectedContact">

                <div class="no-contact-selected">

                    <div class="no-contact-icon">
                        <mat-icon class="s-128 fade-text">chat</mat-icon>
                    </div>

                    <div class="no-contact secondary-text">
                        {{'CHAT.select_contact_to_conversation' | translate}}
                    </div>

                </div>

            </ng-container>

        </div>
        <typing-animation [userTyping]="userIsTyping()"></typing-animation>
        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center"
             *ngIf="selectedContact">

            <form [formGroup]="replyForm"
                  (ngSubmit)="reply($event)"
                  class="message-text"
                  fxFlex fxLayout="row" fxLayoutAlign="start center">

                <div fxFlex fxLayout="column">
                    <ng-container *ngIf="!editFileMessage">
                        <rich-text-field (inputData)="userTyping($event)" *ngIf="attachmentsC?.attachedCount <= 0" [data]="''" formControlName="message"
                                         [placeholder]="'CHAT.type_your_message' | translate"></rich-text-field>
                    </ng-container>
                    <app-attachments fxFlex="1 1 auto" #attachmentsC [files]="editMessageObj?.files" [allowLargeFiles]="false"
                                     toastMessage="File size limit is only 20mb for chat. Please use PRO-Groups to upload large files."
                                     (filesRemoved)="removeAttachment($event)"></app-attachments>
                </div>

                <button mat-icon-button type="button" *ngIf="editFlag" aria-label="Send message" (click)="resetEdit()" tabindex="-1">
                    <mat-icon id="resetMessage" class="secondary-text">close</mat-icon>
                </button>

                <button mat-icon-button type="button"
                        *ngIf="!editFlag || (editFlag && editFileMessage)"
                        aria-label="Attachments" (click)="attachmentsC.pickFile()">
                    <mat-icon class="secondary-text">attachment</mat-icon>
                </button>

                <button [disabled]="isMessageSent" mat-icon-button type="submit" (click)="reply($event)" aria-label="Send message">
                    <mat-icon class="secondary-text">send</mat-icon>
                </button>

            </form>

        </div>

    </div>
    <!-- / Chat -->

</div>
