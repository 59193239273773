import { Injectable } from "@angular/core";
import { ThreadComment } from "../thread/thread.model";

export type TaskModuleType = "planning" | "time_tracker";

export interface Task {
  id: number;
  clinicId: number;
  title: string;
  description: string;
  docType: string;
  reporterId: number;
  taskStart: string;
  taskEnd: string;
  taskStatus: string;
  timeInterval: number;
  showTask: boolean;
  showCalender: boolean;
  createdAt: string;
  updatedAt: string;
  clinicName: string;
  docCreator: any;
  docNotification: boolean;
  isAdmin: boolean;
  subscribedUsers: SubUser[];
  files: any[];
  docComments: ThreadComment[];
  clinicDocCommentsCount: number;
  clinicDocCommentFileCount: number;
  clinicDocFileCount: number;
  reporter: any;
  systemGeneratedTask?: boolean;
  state: string;
}

export interface SubUser {
  id: number;
  fullName: string;
  file: any;
}

export interface TaskComment {
  id: number;
  userId: number;
  clinicId: number;
  comment: string;
  commentType: string;
  createdAt: string;
  userCommenting: string;
  files: any;
  alreadyRead: boolean;
  commentReadCount: number;
  comment_reads: any[];
  isSystemGeneratedComment: boolean;
}

export interface TaskSubUser {
  id: number;
  fullName: string;
  file: any;
  requestStatus: string;
}

@Injectable({
  providedIn: "root",
})
export class TaskCommentAdapter {
  public adapt(item: any): TaskComment {
    return {
      comment: item.comment,
      commentType: item.comment_type,
      createdAt: item.created_at,
      clinicId: item.clinic_id,
      files: item.files,
      id: item.id,
      userCommenting: item.comment_creator,
      userId: item.user_id,
      commentReadCount: item.comment_reads_count,
      comment_reads: item.comment_reads,
      alreadyRead: item.already_read,
      isSystemGeneratedComment: item.is_system_generated,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class SubUserAdapter {
  public adapt(item: any): SubUser {
    return {
      file: item.file,
      fullName: item.full_name,
      id: item.id,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class TaskGroupUsersAdapter {
  public adapt(item: any): TaskSubUser {
    return {
      requestStatus: item.request_status,
      file: item.file,
      fullName: item.full_name,
      id: item.user_id,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class TaskAdapter {
  public adapt(item: any): Task {
    const commentAdapter = new TaskCommentAdapter();
    const subUserAdapter = new SubUserAdapter();

    return {
      clinicName: item.clinic_name,
      docCreator: item.doc_creator,
      files: item.doc_files,
      docNotification: item.doc_notification_on,
      isAdmin: item.is_admin,
      clinicDocCommentsCount: +item.clinic_doc_comments_count,
      clinicDocCommentFileCount: +(item.clinic_doc_comment_file_count || 0),
      clinicDocFileCount: +(item.clinic_doc_file_count || 0),
      clinicId: item.clinic_id,
      createdAt: item.created_at,
      description: item.description,
      docType: item.doc_type,
      id: item.id,
      reporterId: item.reporter_id,
      showCalender: item.show_calendar,
      showTask: item.show_task,

      subscribedUsers: item.doc_sub_users
        ? item.doc_sub_users.map((data) => subUserAdapter.adapt(data))
        : [],

      docComments: item.clinic_doc_comments
        ? item.clinic_doc_comments.map((data) => commentAdapter.adapt(data))
        : [],

      taskEnd: item.task_end,
      taskStart: item.task_start,
      taskStatus: item.task_status,
      timeInterval: item.time_interval,
      title: item.title,
      updatedAt: item.updated_at,
      reporter: item.reporter,
      systemGeneratedTask: item.system_generated_task,
      state: item.state,
    };
  }
}
