import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { RestService } from "../../service/api/rest.service";
import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { GetTrackerDataPayload, GetTrackerDataResponse, GetTrackerSummaryPayload, GetTrackerSummaryResponse, PostExportAsCsv, TimeTrackerSummary, TimeTrackerTask } from "./time-tracker-task.model";
import * as moment from "moment";

@Injectable()
export class TimeTrackerTaskDataService extends DefaultDataService<TimeTrackerTask> {
  private _baseClinic = "clinics";
  calendarViewMode = new Subject();

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private rest: RestService
  ) {
    super("TimeTrackerTask", http, httpUrlGenerator);
  }

  getTrackerData(
    clinicId: number,
    payload: GetTrackerDataPayload
  ): Observable<TimeTrackerTask[]> {
    return this.rest
      .post(`${this._baseClinic}/${clinicId}/clinic_check_ins/tracker_data`, {
        user_ids: payload.user_ids || [],
        start_date: payload.start_date || "",
        end_date: payload.end_date || "",
      })
      .pipe(
        map((resp: GetTrackerDataResponse) => {
          const tasks: TimeTrackerTask[] = [];

          resp.check_ins.forEach((task: TimeTrackerTask) => {
            tasks.push(task);
          });

          return tasks;
        })
      );
  }

  getTrackerSummary(
    clinicId: number,
    filterType: string,
    start_date: string,
    end_date: string,
    payload: GetTrackerSummaryPayload
  ): Observable<TimeTrackerSummary[]> {
    return this.rest
      .post(
        `${this._baseClinic}/${clinicId}/clinic_check_ins/tracker_time_sum_data`,
        {
          user_ids: payload.user_ids || [],
          filter_type: filterType,
          ...(filterType === "custom" &&
            start_date && { start_date: start_date }),
          ...(filterType === "custom" && end_date && { end_date: end_date }),
          current_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        }
      )
      .pipe(
        map((resp: GetTrackerSummaryResponse) => {
          const summaries: TimeTrackerSummary[] = [];

          resp.check_ins.forEach((summary: TimeTrackerSummary) => {
            summaries.push(summary);
          });

          return summaries;
        })
      );
  }

  exportDataAsCsv(
    clinicId: number,
    payload: PostExportAsCsv
  ): Observable<{ message: string }> {
    return this.rest.post(`clinics/${clinicId}/timetrack_export`, {
      from_date: payload.from_date || "",
      to_date: payload.to_date || "",
    });
  }

  getCheckinCheckoutTime(payload: {
    user_ids: number | number[];
    clinicId: number;
    filterType: string;
    dateRange: { fromDate: string; toDate: string };
    page: number;
    limit: number;
  }): Observable<any> {
    return this.rest.post(
      `clinics/${payload.clinicId}/clinic_check_ins/tracker_data`,
      {
        filter_type: payload.filterType,
        user_ids: payload.user_ids || [],

        ...(payload.filterType === "custom" &&
          payload.dateRange.fromDate && {
            start_date: payload.dateRange.fromDate,
          }),

        ...(payload.filterType === "custom" &&
          payload.dateRange.toDate && {
            end_date: payload.dateRange.toDate,
          }),

        page: payload.page,
        limit: payload.limit,
      }
    );
  }

  updateCheckinCheckoutTime(data: {
    task_id: number;
    clinic_id: number | undefined;
    check_in_time: string;
    check_out_time: string;
  }): Observable<any> {
    return this.rest.put(
      `clinics/${data.clinic_id}/clinic_check_ins/${data.task_id}`,
      {
        check_in_time: data.check_in_time,
        check_out_time: data.check_out_time,
      }
    );
  }

  addCheckinCheckoutTime(
    clinicId: number | undefined,
    payload: {
      date: string;
      check_in_time: string;
      check_out_time: string;
      checkin_lat: string | null;
      checkin_lng: string | null;
      checkout_lat: string | null;
      checkout_lng: string | null;
      user_id?: string;
    }
  ): Observable<any> {
    return this.rest.post(
      `clinics/${clinicId}/clinic_check_ins/create_manual_check_in`,
      payload
    );
  }
}
