import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { formatDate } from "@angular/common";
import { TaskDataService } from "../../../../../store/task/task-data.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { NewAuthDataService } from "../../../../../service/api/newAuth-data.service";
import * as _ from "lodash";
import { ActionCableService } from "../../../../../service/socket/action-cable.service";
import { TaskCommentAdapter } from "../../../../../store/task/task.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AttachmentsComponent } from "../../attachments/attachments.component";
import { ThreadsDataService } from "../../../../../store/thread/threads-data.service";
import { TasksEntityService } from "../../../../../store/task/task-entity.service";
import { FriendDataService } from "../../../../../store/friend/friend-data.service";
import { User } from "../../../../../types";
import { DeleteConfirmationDialogComponent } from "../../../groups/group/sidenavs/settings/user-setting/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MY_FORMATS } from "../../../profile/tabs/profile-about/basic-information-form/basic-information-form.component";
import { IGroup } from "../../../../../store/group/group.model";
import { GroupUsersEntityService } from "app/store/group/GroupUsers/group-users-entity.service";
import { ThreadsEntityService } from "app/store/thread/threads-entity.service";

@Component({
  selector: "app-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TaskComponent implements OnInit, OnDestroy {
  list: any;
  hours = [];
  currentDate = moment();
  archived = false;
  timeInterval: any = [];
  selectedUsers: any = [];
  calendarShow = true;
  taskShow = true;
  today = new Date();
  todaysDataTime = "";
  apiResponse = [];
  taskList: any[] = [];
  groupUserList: any = [];
  dialogTitle: string;
  componentActive = true;
  taskForm: FormGroup;
  groupsList: any[];
  @ViewChild("attachmentsC", { static: false })
  attachmentsC: AttachmentsComponent;

  @ViewChild("checklistMenuTrigger")
  checklistMenu: MatMenuTrigger;

  @ViewChild("newCheckListTitleField")
  newCheckListTitleField;

  categoriesFormArray: FormArray;
  groupSelected: boolean;
  groupIndex = 0;
  reporterObj: any;
  subUserList: any = [];
  currentUser: User;
  // Private
  private _unsubscribeAll: Subject<any>;
  private currentSelectedGroup: any;
  intervalValueIndex = 5;
  newTask: boolean;
  taskData: any;
  subUserChange = false;
  archivedTask = false;
  @ViewChild("groupNameMenu") trigger: MatMenuTrigger;
  private taskCommentsUpdate: Subscription;
  loadingProgress = false;
  allowGroupEdit = true;
  removedAttachment: any = [];
  showAttachment = false;
  groupTag = true;
  allDaySelected = false;
  currentClinic: IGroup;
  allowLargeFiles: boolean;
  isMyDoc = false;
  showEndTime = false;
  type: string;
  module: "planning" | "time_tracker" = "planning";
  isAdmin = false;
  intervalId: any;
  readonly = false;

  constructor(
    public matDialogRef: MatDialogRef<TaskComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _matDialog: MatDialog,
    private store: Store<any>,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private taskDataService: TaskDataService,
    private toast: ToastrService,
    private taskEntityService: TasksEntityService,
    private groupUserEntityService: GroupUsersEntityService,
    private authService: NewAuthDataService,
    private threadsDataService: ThreadsDataService,
    private actionCableService: ActionCableService,
    private taskCommentAdapter: TaskCommentAdapter,
    private _activatedRoute: ActivatedRoute,
    private friendDataService: FriendDataService,
    private router: Router,
    private progressBarService: FuseProgressBarService,
    private threadEntity: ThreadsEntityService
  ) {
    this.todaysDataTime = formatDate(
      this.today,
      "hh:mm:ss a",
      "en-US",
      "+0530"
    );
    this._unsubscribeAll = new Subject();

    this.categoriesFormArray = new FormArray([]);
    this.groupSelected = false;

    for (let i = 5; i <= 55; i += 5) {
      this.timeInterval.push(i + " mins");
    }
    for (let i = 1; i <= 8; i++) {
      this.timeInterval.push(i + " hrs");
    }
    this.timeInterval.pop();
    this.timeInterval.unshift("Select Time");
    this.timeInterval.unshift("All Day");

    this.readonly = _data.readonly;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    if (this._data?.module === "time_tracker") {
      this.module = "time_tracker";
    }

    this.newTask = this._data.new;
    this.type = this._data.type || "task";

    this.authService.getUser().subscribe((user) => {
      this.currentUser = user;
      this.dialogTitle = "Select Group";
      if (this._data.taskData?.startDate) {
        this.currentDate = this._data.taskData.startDate;
      }

      this.taskForm = this._formBuilder.group(
        {
          clinicId: ["", Validators.required],
          title: [""],
          description: ["", Validators.required],
          task: [""],
          date: [moment(this.currentDate)],
          startTime: [moment(this.currentDate).format("HH:mm")],
          timeInterval:
            this.module === "planning" ? [this.timeInterval[6]] : "Select Time",
          showCalendar: [true],
          showTask: [true],
          taskEndDate: [""],
        },
        { validator: this.timeRangeValidator() }
      );

      if (
        this._data.taskData?.timeInterval &&
        +this._data.taskData?.timeInterval === 1439
      ) {
        this.taskForm.patchValue({
          timeInterval: `All Day`,
        });
        this.allDaySelected = true;
      }

      this.setTasks();

      this.taskForm.patchValue({
        task: this.module === "planning" ? "To Do" : "In Progress",
        ...(this.module === "planning"
          ? {}
          : {
              timeInterval: "Select Time",
            }),
      });

      if (
        this._data.state?.name &&
        // Time tracker doesn't allow tasks in Todo
        (this._data.state?.name !== "To Do" || this.module !== "time_tracker")
      ) {
        this.taskForm.patchValue({
          task: this._data.state?.name,
        });
      }

      // FIXME: Why we do even need timeout?
      setTimeout(() => {
        this.taskDataService
          .getUserClinicsWithCategories()
          .subscribe((data) => {
            this.groupsList = data.clinics;
            const communityGroupIndex = this.groupsList.findIndex(
              (x) => x.is_default === true
            );
            this.groupsList.splice(communityGroupIndex, 1);
            this.groupsList.length < 1
              ? (this.groupTag = false)
              : (this.groupTag = true);
            if (this._data.currentClinic) {
              this.currentClinic = this._data.currentClinic;
              this.allowLargeFiles =
                this.currentClinic?.clinicModules?.large_file_size ?? false;
              this.allowGroupEdit = false;
              this.groupSelection(
                null,
                _.findIndex(this.groupsList, {
                  id: +this._data.currentClinic.id,
                })
              );
            }
          });
      }, 500);

      if (!this._data.new) {
        this.showAttachment = true;
        this.allowGroupEdit = false;
        this.taskData = this._data.taskData;
        if (!(this.taskData.files?.length > 0)) {
          this.showAttachment = false;
        }
        let description = this.taskData.description;
        const formattedDescription = description.replace(/\n/g, "<br>");
        this.taskForm.patchValue({
          clinicId: this.taskData.clinicId,
          title: this.taskData.title,
          description: formattedDescription,
          date: this.taskData.taskStart,
          startTime: moment(this.taskData.taskStart).format("HH:mm"),
          showCalendar: this.taskData.showCalendar,
          showTask: this.taskData.showTask,
          taskEndDate: this.taskData.taskEnd
            ? moment(this.taskData.taskEnd).format("HH:mm")
            : "",
        });

        if (
          this?.taskData?.timeInterval === 0 ||
          this.module === "time_tracker" ||
          this?.taskData?.systemGeneratedTask
        ) {
          this.taskForm.patchValue({
            timeInterval: `Select Time`,
          });

          // System Generated task is Check-In Task
          if (this?.taskData?.systemGeneratedTask) {
            this.taskForm.patchValue({
              taskEndDate: this.taskData.taskEnd
                ? moment(this.taskData.taskEnd).format("HH:mm")
                : "",
            });
          }

          this.intervalChange({ isUserInput: true }, 1);
        } else if (this.taskData.timeInterval) {
          const timeI = +this.taskData.timeInterval;
          if (timeI > 55) {
            if (timeI === 1439) {
              this.allDaySelected = true;
              this.taskForm.patchValue({
                timeInterval: `All Day`,
              });
            } else {
              this.taskForm.patchValue({
                timeInterval: `${timeI / 60} hrs`,
              });
            }
          } else {
            this.taskForm.patchValue({
              timeInterval: `${timeI} mins`,
            });
          }
        }

        switch (this.taskData.taskStatus) {
          case "to_do":
            this.taskForm.patchValue({
              task: this.module === "time_tracker" ? "In Progress" : "To Do",
            });

            break;
          case "in_progress":
            this.taskForm.patchValue({
              task: "In Progress",
            });
            break;
          case "done":
            this.taskForm.patchValue({
              task: "Done",
            });
            break;
          case "archived":
            this.taskForm.patchValue({
              task: "Archived",
            });
            break;
        }
        this.dialogTitle = this.taskData.clinicName;
        this.taskShow = this.taskData.showTask;
        this.calendarShow = this.taskData.showCalender;

        // FIXME: Why we do even need timeout?
        setTimeout(() => {
          this.taskDataService
            .getGroupUsers(this.taskData.clinicId)
            .subscribe((data: any) => {
              this.groupUserList.length = 0;
              this.apiResponse = data;
              for (const item of data) {
                this.groupUserList.push(item);
              }
              this.reporterObj = this.apiResponse.find((obj: any) => {
                return obj.id === this.taskData.reporterId;
              });
              this.subUserList = this.groupUserList.filter(
                (item) => item.id !== this.reporterObj.id
              );
              this.selectedUsers = this.taskData.subscribedUsers;
              this.subUserList = this.subUserList.map((user) => {
                user.selectedUser = true;
                user.selectedUser =
                  this.selectedUsers.filter((item: any) => {
                    return item.id === user.id;
                  }).length > 0;
                return user;
              });
            });
        }, 50);

        if (this.currentUser.id === this.taskData.docCreator.id) {
          this.archivedTask = true;
        }
        this.currentSelectedGroup = this.groupsList?.find(
          (group) => +group.id === +this.taskData.clinicId
        );
        this.allowLargeFiles = this.currentSelectedGroup?.allow_large_files;
      }

      if (this.module === "time_tracker") {
        this.intervalChange({ isUserInput: true }, 1);
      }
      this.setTasks();
    });

    this.isAdmin = this.groupUserEntityService.isAdmin;
    this.groupUserEntityService.isAdmin$.subscribe({
      next: (v) => {
        this.isAdmin = v;
        const action =
          !this.isAdmin && this.module === "time_tracker"
            ? "disable"
            : "enable";

        this.taskForm[action]({
          onlySelf: false,
        });
        this.setTasks();
      },
    });
  }

  updateGroupUsers($event: any, group: any): void {
    if (this.groupsList.length > 0) {
      this.currentSelectedGroup = "";
      this.currentSelectedGroup = this.groupsList.filter(
        (obj) => obj.id === group.id
      )[0];
    }
  }

  groupSelection(event: any, index: any): void {
    if (this.groupsList[index]?.name === "My Docs") {
      this.isMyDoc = true;
    } else {
      this.isMyDoc = false;
    }
    this.dialogTitle = this.groupsList[index]?.name;
    this.taskForm.patchValue({
      clinicId: this.groupsList[index].id,
    });
    this.taskDataService
      .getGroupUsers(this.groupsList[index].id)
      .subscribe((data) => {
        this.groupUserList.length = 0;
        this.apiResponse = data.filter(
          (res) => res.requestStatus === "approved"
        );
        for (const item of this.apiResponse) {
          this.groupUserList.push(item);
        }
        this.reporterObj = this.groupUserList.find((obj) => {
          return obj.id === this.currentUser.id;
        });
        if (this.subUserList) {
          this.subUserList.length = 0;
        }
        if (this.selectedUsers) {
          this.selectedUsers.length = 0;
        }
        this.subUserList = this.groupUserList.filter(
          (item) => item !== this.reporterObj
        );
      });
    this.groupIndex = index;
    this.groupSelected = !this.groupSelected;
    this.allowLargeFiles = this.groupsList[index]?.allow_large_files;
    if (this.attachmentsC?.attachments) {
      this.attachmentsC.attachments = [];
    }
  }

  get f(): any {
    return this.taskForm.controls;
  }

  get t(): FormArray {
    return this.f.categories as FormArray;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.taskCommentsUpdate?.unsubscribe();
    this.componentActive = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async saveGroup(): Promise<void> {
    this.beforeSave();
    if (this.taskForm.valid) {
      if (
        this.taskForm.get("showTask")?.value === false &&
        this.taskForm.get("showCalendar")?.value === false
      ) {
        this.toast.error("Please select task or calender.", "Error");
      } else {
        this.loadingProgress = true;
        if (this.removedAttachment.length > 0) {
          for (const removedAttachmentElement of this.removedAttachment) {
            await this.threadsDataService
              .deleteFile(removedAttachmentElement)
              .toPromise();
          }
          this.removedAttachment = [];
        }
        const tempSubUsers: any = [];
        this.selectedUsers.forEach((item: any) => {
          tempSubUsers.push({
            user_id: item.id,
          });
        });
        let files: any = [];
        if (this.attachmentsC?.attachedCount > 0) {
          const res = await this.attachmentsC.directUpload(
            this.taskForm.get("clinicId")?.value
          );
          if (typeof res[0] === "string" && res[0]?.includes("Error")) {
            this.loadingProgress = false;
            this.toast.error("Clinic file uploading limit exceeded", "Error");
            return;
          } else {
            files = res.map((f) => f.signed_id);
          }
        }
        if (this._data.taskData?.files?.length) {
          files = [
            ...files,
            ...this._data.taskData?.files.map((f) => f.signed_id),
          ];
        }
        let tempTaskStatus = "";
        switch (this.taskForm.get("task")?.value) {
          case "To Do":
            tempTaskStatus = "to_do";
            break;
          case "In Progress":
            tempTaskStatus = "in_progress";
            break;
          case "Done":
            tempTaskStatus = "done";
            break;
          case "Archived":
            tempTaskStatus = "archived";
            break;
        }
        const date =
          moment(this.taskForm.get("date")?.value).format("MM/DD/YYYY") +
          " " +
          this.taskForm.get("startTime")?.value;
        const taskStartDate = moment(date, "MM/DD/YYYY HH:mm");
        const taskStartDateISO = taskStartDate.toISOString();
        const ti = this.taskForm.get("timeInterval")?.value.split(" ");
        let taskEndDate: any = "";
        let tim = +ti[0];
        if (ti[1] === "hrs") {
          tim = 60 * +ti[0];
        }
        if (ti[0] === "All") {
          tim = 60 * 24 - 1;
        }
        if (ti[0] === "Select") {
          const endDate =
            moment(this.taskForm.get("date")?.value).format("MM/DD/YYYY") +
            " " +
            this.taskForm.get("taskEndDate")?.value;
          if (this.taskForm.get("taskEndDate")?.value) {
            taskEndDate = moment(endDate, "MM/DD/YYYY HH:mm").toISOString();
          }
          tim = this.calculateTimeDifferenceInMinutes(
            this.taskForm.get("startTime")?.value,
            this.taskForm.get("taskEndDate")?.value
          );
        }

        taskStartDate.add(tim, "minute");
        const taskEndValue =
          ti[0] === "Select"
            ? taskEndDate
            : this._data?.isGroupTimeline
            ? null
            : taskStartDate.toISOString();
        const taskEndCondition = taskEndValue;
        const taskData = {
          doc: {
            clinic_id: this.taskForm.get("clinicId")?.value,
            doc_type: this.module === "planning" ? "task" : "time_tracker",
            title: this.taskForm.get("title")?.value,
            description: this.taskForm.get("description")?.value,
            reporter_id: this.reporterObj.id,
            task_start: taskStartDateISO,
            // task_end:
            //   ti[0] === "Select" ? taskEndDate : taskStartDate.toISOString(),
            ...(taskEndCondition && { task_end: taskEndValue }),
            task_status: tempTaskStatus,
            time_interval: ti[0] === "Select" ? 0 : tim,
            show_task: this.taskForm.get("showTask")?.value,
            show_calendar: this.taskForm.get("showCalendar")?.value,
            doc_sub_users_attributes: tempSubUsers,
            files: files,
          },
          activity_type: "",
        };
        if (this.newTask) {
          this.taskDataService.createTask(taskData, this.module).subscribe(
            (data) => {
              this.toast.success("", "Task Created");
              if (data.taskStatus === "done") {
                if (this.router.url !== "/home") {
                  this.taskEntityService.addOneToCache({
                    ...data,
                    id: data.id,
                  });
                }
              } else {
                this.taskEntityService.addOneToCache({ ...data, id: data.id });
              }
              this.loadingProgress = false;
              this.matDialogRef.close("Task Created");
            },
            (error) => {
              this.loadingProgress = false;
              this.toast.error(error.errors.join(", "), "Error");
            }
          );
        } else {
          if (this.attachmentsC?.attachedCount > 0) {
            taskData.activity_type = "file_added";
          } else {
            taskData.activity_type = "";
          }
          this.taskDataService
            .updateTask(taskData, this.taskData.id, this.module)
            .subscribe(
              (data) => {
                this.toast.success("", "Task Updated");
                if (data.taskStatus === "done") {
                  if (this.router.url === "/home") {
                    this.taskEntityService.removeOneFromCache(this.taskData.id);
                  } else {
                    this.taskEntityService.updateOneInCache({
                      ...data,
                      id: data.id,
                    });
                    if (this._data?.isGroupTimeline) {
                      this.threadEntity.updateOneInCache({
                        ...data,
                        id: data.id,
                        modified: true,
                      });
                    }
                  }
                } else {
                  this.taskEntityService.updateOneInCache({
                    ...data,
                    id: data.id,
                  });
                  if (this._data?.isGroupTimeline) {
                    this.threadEntity.updateOneInCache({
                      ...data,
                      id: data.id,
                      modified: true,
                    });
                  }
                }
                this.loadingProgress = false;
                this.matDialogRef.close("Task Updated");
              },
              (error) => {
                this.loadingProgress = false;
                this.toast.error(error.error, "Error");
              }
            );
        }
      }
    } else if (this.taskForm.get("clinicId")?.errors) {
      this.toast.error("Pleae Select a group First", "Error");
    } else if (this.taskForm.get("description")?.errors) {
      this.toast.error("Please Enter Description", "Error");
    }
  }

  async savePlan(): Promise<void> {
    this.beforeSave();
    if (this.taskForm.valid) {
      if (
        this.taskForm.get("showTask")?.value === false &&
        this.taskForm.get("showCalendar")?.value === false
      ) {
        this.toast.error("Please select task or calender.", "Error");
      } else {
        this.loadingProgress = true;
        if (this.removedAttachment.length > 0) {
          for (const removedAttachmentElement of this.removedAttachment) {
            await this.threadsDataService
              .deleteFile(removedAttachmentElement)
              .toPromise();
          }
          this.removedAttachment = [];
        }
        const tempSubUsers: any = [];
        this.selectedUsers.forEach((item: any) => {
          tempSubUsers.push({
            user_id: item.id,
          });
        });
        let files: any = [];
        if (this.attachmentsC?.attachedCount > 0) {
          const res = await this.attachmentsC.directUpload(
            this.taskForm.get("clinicId")?.value
          );
          if (typeof res[0] === "string" && res[0]?.includes("Error")) {
            this.loadingProgress = false;
            this.toast.error("Clinic file uploading limit exceeded", "Error");
            return;
          } else {
            files = res.map((f) => f.signed_id);
          }
        }
        if (this._data.taskData?.files?.length) {
          files = [
            ...files,
            ...this._data.taskData?.files.map((f) => f.signed_id),
          ];
        }
        let tempTaskStatus = "";
        switch (this.taskForm.get("task")?.value) {
          case "To Do":
            tempTaskStatus = "to_do";
            break;
          case "In Progress":
            tempTaskStatus = "in_progress";
            break;
          case "Done":
            tempTaskStatus = "done";
            break;
          case "Archived":
            tempTaskStatus = "archived";
            break;
        }
        const date =
          moment(this.taskForm.get("date")?.value).format("MM/DD/YYYY") +
          " " +
          this.taskForm.get("startTime")?.value;
        const taskStartDate = moment(date, "MM/DD/YYYY HH:mm");
        const taskStartDateISO = taskStartDate.toISOString();
        const ti = this.taskForm.get("timeInterval")?.value.split(" ");
        let taskEndDate: any = "";
        let tim = +ti[0];
        if (ti[1] === "hrs") {
          tim = 60 * +ti[0];
        }
        if (ti[0] === "All") {
          tim = 60 * 24 - 1;
        }
        if (ti[0] === "Select") {
          const endDate =
            moment(this.taskForm.get("date")?.value).format("MM/DD/YYYY") +
            " " +
            this.taskForm.get("taskEndDate")?.value;

          taskEndDate = moment(endDate, "MM/DD/YYYY HH:mm").toISOString();
          tim = this.calculateTimeDifferenceInMinutes(
            this.taskForm.get("startTime")?.value,
            this.taskForm.get("taskEndDate")?.value
          );
        }

        taskStartDate.add(tim, "minute");
        const taskData = {
          doc: {
            clinic_id: this.taskForm.get("clinicId")?.value,
            doc_type: this.module === "planning" ? "planner" : "time_tracker",
            title: this.taskForm.get("title")?.value,
            description: this.taskForm.get("description")?.value,
            reporter_id: this.reporterObj.id,
            task_start: taskStartDateISO,
            task_end:
              ti[0] === "Select" ? taskEndDate : taskStartDate.toISOString(),
            task_status: tempTaskStatus,
            time_interval: ti[0] === "Select" ? 0 : tim,
            show_task: this.taskForm.get("showTask")?.value,
            show_calendar: this.taskForm.get("showCalendar")?.value,
            doc_sub_users_attributes: tempSubUsers,
            files: files,
          },
          activity_type: "",
        };
        if (this.newTask) {
          this.taskDataService.createPlan(taskData, this.module).subscribe(
            (data) => {
              this.toast.success("", "Task Created");

              if (data.taskStatus === "done") {
                if (this.router.url !== "/home") {
                  this.taskEntityService.addOneToCache({
                    ...data,
                    id: data.id,
                  });
                }
              } else {
                this.taskEntityService.addOneToCache({ ...data, id: data.id });
              }
              this.loadingProgress = false;
              this.matDialogRef.close("Task Created");
            },
            (error) => {
              this.loadingProgress = false;
              this.toast.error(error.errors.join(", "), "Error");
            }
          );
        } else {
          if (this.attachmentsC?.attachedCount > 0) {
            taskData.activity_type = "file_added";
          } else {
            taskData.activity_type = "";
          }
          this.taskDataService
            .updatePlan(taskData, this.taskData.id, this.module)
            .subscribe(
              (data) => {
                this.toast.success("", "Task Updated");
                if (data.taskStatus === "done") {
                  if (this.router.url === "/home") {
                    this.taskEntityService.removeOneFromCache(this.taskData.id);
                  } else {
                    this.taskEntityService.updateOneInCache({
                      ...data,
                      id: data.id,
                    });
                  }
                } else {
                  this.taskEntityService.updateOneInCache({
                    ...data,
                    id: data.id,
                  });
                }
                this.loadingProgress = false;
                this.matDialogRef.close("Task Updated");
              },
              (error) => {
                this.loadingProgress = false;
                this.toast.error(error.error, "Error");
              }
            );
        }
      }
    } else if (this.taskForm.get("clinicId")?.errors) {
      this.toast.error("Pleae Select a group First", "Error");
    } else if (this.taskForm.get("description")?.errors) {
      this.toast.error("Please Enter Description", "Error");
    }
  }

  calculateTimeDifferenceInMinutes(startTime, endTime): any {
    const [startHour, startMinute] = startTime.split(":");
    const [endHour, endMinute] = endTime.split(":");
    const startDate: any = new Date(0, 0, 0, startHour, startMinute);
    const endDate: any = new Date(0, 0, 0, endHour, endMinute);

    const timeDifferenceInMilliseconds = endDate - startDate;
    const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
    return timeDifferenceInMinutes;
  }

  timeRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startTime = control.get("startTime")?.value;
      const taskEndDate = control.get("taskEndDate")?.value;
      const selectedDate = moment(control.get("date")?.value);
      const currentDate = moment();
      if (startTime && taskEndDate) {
        const startMinutes = this.convertToMinutes(startTime);
        const endMinutes = this.convertToMinutes(taskEndDate);
        if (!this.newTask) {
          const timeError = this.checkCurrentTime(
            startTime,
            taskEndDate,
            control
          );
          if (timeError) return { timeError: true };
        }
        if (startMinutes >= endMinutes) {
          control.get("taskEndDate")?.setErrors({
            timeRangeError: "Start time must be smaller than end time",
          });
          return { timeRangeError: "Start time must be smaller than end time" };
        }
        if (this.module == "time_tracker") {
          if (selectedDate.isBefore(currentDate, "day")) {
            return null;
          }
          if (selectedDate.isSameOrAfter(currentDate, "day")) {
            const currentMinutes = this.convertToMinutes(
              moment().format("HH:mm")
            );
            if (startMinutes > currentMinutes) {
              control.get("startTime")?.setErrors({
                futureTimeError: "You cannot add tasks planned ahead of time",
              });
              return {
                futureTimeError: "You cannot add tasks planned ahead of time",
              };
            }
          }
        }
      }
      if (!this.newTask && startTime && !taskEndDate) {
        const timeError = this.checkCurrentTime(
          startTime,
          taskEndDate,
          control
        );
        if (timeError) return { timeError: true };
      }
      return null;
    };
  }

  checkCurrentTime(
    startTime: string,
    taskEndDate: string,
    control: AbstractControl
  ): ValidationErrors | null {
    const startMinutes = this.convertToMinutes(startTime);
    const endMinutes = this.convertToMinutes(taskEndDate);
    const now = moment();
    const currentMinutes = now.hours() * 60 + now.minutes();
    if (endMinutes === currentMinutes || startMinutes === currentMinutes) {
      let previousMinute = now.minutes();
      this.intervalId = setInterval(() => {
        const now = moment();
        const currentMinute = now.minutes();
        if (currentMinute !== previousMinute) {
          this.taskForm.get("startTime")?.updateValueAndValidity();
          this.taskForm.get("taskEndDate")?.updateValueAndValidity();
          previousMinute = currentMinute;
        }
      }, 1000);
      return { timeError: true };
    }
    return null;
  }

  convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
    return hours * 60 + minutes;
    // return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }

  removeAttachment($event: any): void {
    this.removedAttachment.push($event.signed_id);
    this._data.taskData.files = this._data.taskData.files?.filter(
      ({ signed_id }) => {
        return $event.signed_id !== signed_id;
      }
    );
  }

  taskStatus(task: any): void {
    // Time tracker can't be in To Do Status
    if (this.module !== "time_tracker" || task !== "To Do") {
      if (task) {
        this.archived = true;
      }
      this.taskForm.patchValue({
        task: task,
      });
    }
  }

  subUserSelection(event, userIndex): void {
    if (
      event.checked &&
      this.selectedUsers.indexOf(this.subUserList[userIndex]) === -1
    ) {
      this.selectedUsers.push(this.subUserList[userIndex]);
    } else {
      userIndex = this.selectedUsers.findIndex(
        (user) => user.id === this.subUserList[userIndex].id
      );
      this.selectedUsers.splice(userIndex, 1);
    }
  }

  showCalendar(): void {
    this.calendarShow = !this.calendarShow;
    this.taskForm.patchValue({
      showCalendar: this.calendarShow,
    });
  }

  showTask(): void {
    this.taskShow = !this.taskShow;
    this.taskForm.patchValue({
      showTask: this.taskShow,
    });
  }

  intervalChange(event, index): any {
    if (event.isUserInput) {
      if (+index === 1) {
        this.taskForm
          .get("taskEndDate")
          ?.setValidators([this.timeRangeValidator()]); // remove required validation from checkout time
        this.taskForm.updateValueAndValidity();
        this.showEndTime = true;
      } else {
        this.taskForm.get("taskEndDate")?.clearValidators();
        this.taskForm.updateValueAndValidity();
        this.showEndTime = false;
      }
      if (+index === 0) {
        this.taskForm.patchValue({
          startTime: "00:00",
        });
        this.allDaySelected = true;
      } else {
        this.allDaySelected = false;
      }

      this.intervalValueIndex = index;
    }
  }

  userSelectionReporter(event, userIndex): void {
    if (this.readonly) return;

    this.subUserChange = true;
    this.reporterObj = this.groupUserList[userIndex];
    this.subUserList = this.groupUserList.filter(
      (item) => item !== this.reporterObj
    );
    this.selectedUsers = this.selectedUsers.filter(
      (item) => item.id !== this.reporterObj.id
    );

    this.subUserList = this.subUserList.map((data: any) => {
      data.selectedUser = false;
      data.selectedUser =
        this.selectedUsers.filter((item) => item.id === data.id).length > 0
          ? true
          : false;
      return data;
    });
  }

  openGroupMenu(menuTrigger): void {
    menuTrigger.openMenu();
  }

  subMenuListener(): void {
    if (this.readonly) return;

    if (this.dialogTitle === "Select Group") {
      this.toast.error("Please Select a Group First", "Error");
    }
  }

  editAttachment(): void {
    this.showAttachment = !this.showAttachment;
    this.groupSelected = true;
  }

  private beforeSave(): void {
    let description = "";
    const des = this.taskForm.get("description")?.value?.trim() || "";
    const title = this.taskForm.get("title")?.value?.trim() || "";
    if (title === "" && des === "") {
      if (this.attachmentsC?.attachedCount > 0) {
        description =
          this.attachmentsC.attachedCount === 1
            ? `${this.attachmentsC.attachments[0].file.name}`
            : `${this.attachmentsC.attachments[0].file.name} +${
                this.attachmentsC.attachments.length - 1
              }`;
        this.taskForm.patchValue({ description });
      }
    } else if (title === "" && des) {
      this.taskForm.patchValue({ title: des.split(" ").slice(0, 5).join(" ") });
    }
  }

  deleteTask(): void {
    this._matDialog
      .open(DeleteConfirmationDialogComponent, {
        panelClass: "deleteDialog",
        data: {
          title: this.taskData?.title.replace(/<\/?[^>]+(>|$)/g, ""),
          message: "Are you sure you want to delete this Task?",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res[0] === "yes") {
          this.progressBarService.show();
          this.taskDataService.deleteTask(this.taskData.id).subscribe(
            (data) => {
              this.progressBarService.hide();
              this.matDialogRef.close("Task Deleted");
              this.toast.success("", data.message);
              this.taskEntityService.removeOneFromCache(this.taskData.id);
            },
            (error) => {
              this.progressBarService.hide();
              this.toast.error(error.errors.join(", "), "Error");
            }
          );
        }
      });
  }

  disabledNormalUser() {
    if (
      this.newTask ||
      this.isAdmin ||
      this.currentUser.id === this.taskData?.docCreator.id ||
      this.currentUser?.id === this.taskData?.reporterId
    ) {
      return true;
    }
    return false;
  }

  private setTasks() {
    this.taskList = [
      {
        name: "To Do",
        color: "blue-600",
        // Time Tracker tasks can't be planned
        disabled:
          this.module === "time_tracker" ||
          (this.module === "planning" && !this.disabledNormalUser()),
        tooltip:
          this.module !== "time_tracker"
            ? "To Do"
            : "Tasks cannot be planned ahead of time!",
      },
      {
        name: "In Progress",
        color: "blue-600",
        disabled:
          (this.module === "time_tracker" && !this.isAdmin) ||
          (this.module === "planning" && !this.disabledNormalUser()),
        tooltip: "In Progress",
      },
      {
        name: "Done",
        color: "blue-600",
        disabled:
          (this.module === "time_tracker" && !this.isAdmin) ||
          (this.module === "planning" && !this.disabledNormalUser()),
        tooltip: "Done",
      },
      {
        name: "Archived",
        color: "blue-600",
        disabled:
          (this.module === "time_tracker" && !this.isAdmin) ||
          (this.module === "planning" && !this.disabledNormalUser()),
        tooltip: "Archived",
      },
    ];
  }
}
